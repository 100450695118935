define("a24-ember-lib/services/card-datagrid-filter", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        _objUserSession: Ember.inject.service("user-session"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objEmberLibStringsService: Ember.inject.service("a24-strings-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),

        validateSecondaries: function (arrSecondaries) {
            for (var i = 0; i < arrSecondaries.length; i++) {
                var objSecondary = arrSecondaries[i];
                if (objSecondary._bEmptyEntriesOnly) {
                    continue;
                }
                if (Ember.get(objSecondary, "sFilterType") === "int") {
                    if (objSecondary.bFilterRequired) {
                        if (a24Core.isEmpty(objSecondary._iFilterValue) || a24Core.isEmpty(objSecondary._objFilterValue)) {
                            return true;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary.funcFilterValidation)) {
                        var mIntValidationReturn = objSecondary.funcFilterValidation();
                        if (!a24Core.isEmpty(mIntValidationReturn)) {
                            return mIntValidationReturn;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary._objFilterValue) && a24Core.isEmpty(objSecondary._iFilterValue)) {
                        return true;
                    }
                } else if (Ember.get(objSecondary, "sFilterType") === "text") {
                    if (objSecondary.bFilterRequired) {
                        if (a24Core.isEmpty(objSecondary._sFilterValue)) {
                            return true;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary.funcFilterValidation)) {
                        var mTextValidationReturn = objSecondary.funcFilterValidation();
                        if (!a24Core.isEmpty(mTextValidationReturn)) {
                            return mTextValidationReturn;
                        }
                    }
                } else if (Ember.get(objSecondary, "sFilterType") === "bool") {
                    if (objSecondary.bFilterRequired) {
                        if (a24Core.isEmpty(objSecondary._objFilterValue)) {
                            return true;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary.funcFilterValidation)) {
                        var mBoolValidationReturn = objSecondary.funcFilterValidation();
                        if (!a24Core.isEmpty(mBoolValidationReturn)) {
                            return mBoolValidationReturn;
                        }
                    }
                } else if (Ember.get(objSecondary, "sFilterType") === "dropdown") {
                    if (objSecondary.bFilterRequired) {
                        if (a24Core.isEmpty(objSecondary._objFilterValue)) {
                            return true;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary.funcFilterValidation)) {
                        var mDropdownValidationReturn = objSecondary.funcFilterValidation();
                        if (!a24Core.isEmpty(mDropdownValidationReturn)) {
                            return mDropdownValidationReturn;
                        }
                    }
                } else if (Ember.get(objSecondary, "sFilterType") === "dropdownMulti") {
                    if (objSecondary.bFilterRequired) {
                        if (a24Core.isEmpty(objSecondary._arrFilterValue)) {
                            return true;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary.funcFilterValidation)) {
                        var mDropdownMultiValidationReturn = objSecondary.funcFilterValidation();
                        if (!a24Core.isEmpty(mDropdownMultiValidationReturn)) {
                            return mDropdownMultiValidationReturn;
                        }
                    }
                } else if (Ember.get(objSecondary, "sFilterType") === "date") {
                    if (objSecondary.bFilterRequired) {
                        if (a24Core.isEmpty(objSecondary._sFromDate) && a24Core.isEmpty(objSecondary._sToDate)) {
                            if (a24Core.isEmpty(objSecondary._iFilterValue) || objSecondary._iFilterValue === 0) {
                                return true;
                            }
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary._sFromDate) && !a24Core.isEmpty(objSecondary._sToDate)) {
                        var objFromDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                        objFromDate.setDateFromBrowser(objSecondary._sFromDate, this.get("_objLibConstants.DATE_FORMAT"));

                        var objToDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                        objToDate.setDateFromBrowser(objSecondary._sToDate, this.get("_objLibConstants.DATE_FORMAT"));
                        if (objToDate.isBefore(objFromDate)) {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("validationDateBefore", "To Date", "From Date"));
                            return true;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary.funcFilterValidation)) {
                        var mDateValidationReturn = objSecondary.funcFilterValidation();
                        if (!a24Core.isEmpty(mDateValidationReturn)) {
                            return mDateValidationReturn;
                        }
                    }
                    if (!a24Core.isEmpty(objSecondary._objFilterValue) && a24Core.isEmpty(objSecondary._sFromDate) && a24Core.isEmpty(objSecondary._sToDate)) {
                        if (a24Core.isEmpty(objSecondary._iFilterValue) || objSecondary._iFilterValue === 0) {
                            return true;
                        }
                    }
                }
            }
        },
        setValuesForSecondariesFilter: function (arrSecondaries, objFilterValue) {
            if (!a24Core.isEmpty(arrSecondaries) && !a24Core.isEmpty(objFilterValue.arSec)) {
                for (var i = 0; i < arrSecondaries.length; i++) {
                    var objSecondary = arrSecondaries[i];
                    for (var j = 0; j < objFilterValue.arSec.length; j++) {
                        if (objSecondary.sProperty === objFilterValue.arSec[j].sProp) {
                            var objSecondaryQuery = objFilterValue.arSec[j];
                            if (objSecondaryQuery.mValue === "null") {
                                Ember.set(objSecondary, "_bEmptyEntriesOnly", true);
                            } else if (Ember.get(objSecondary, "_bIntFilter")) {
                                Ember.set(objSecondary, "_iFilterValue", objSecondaryQuery.mValue);

                                if (objSecondaryQuery.bAfter) {
                                    Ember.set(objSecondary, "_objFilterValue", {
                                        sValue: this.get("_objEnumEmberLibService.intFilterOptions.BIGGER_THAN.backend")
                                    });
                                } else if (objSecondaryQuery.bBefore) {
                                    Ember.set(objSecondary, "_objFilterValue", {
                                        sValue: this.get("_objEnumEmberLibService.intFilterOptions.SMALLER_THAN.backend")
                                    });
                                } else {
                                    Ember.set(objSecondary, "_objFilterValue", {
                                        sValue: this.get("_objEnumEmberLibService.intFilterOptions.EQUAL_TO.backend")
                                    });
                                }
                            } else if (Ember.get(objSecondary, "_bTextFilter")) {
                                Ember.set(objSecondary, "_sFilterValue", objSecondaryQuery.mValue);
                            } else if (Ember.get(objSecondary, "_bBoolFilter")) {
                                Ember.set(objSecondary, "_objFilterValue", {
                                    sValue: objSecondaryQuery.mValue + ""
                                });
                            } else if (Ember.get(objSecondary, "_bDropDownFilter")) {
                                Ember.set(objSecondary, "_objFilterValue", {
                                    sValue: objSecondaryQuery.mValue
                                });
                            } else if (Ember.get(objSecondary, "_bDropDownMultiFilter")) {
                                var arrFilterValue = objSecondaryQuery.mValue.split("|").map(sValue => {
                                    return {
                                        sValue: sValue
                                    };
                                });
                                Ember.set(objSecondary, "_arrFilterValue", arrFilterValue);
                            } else if (Ember.get(objSecondary, "_bDateFilter")) {
                                Ember.set(objSecondary, "_bInternalValueChange", true);
                                var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                if (!a24Core.isEmpty(objSecondaryQuery.sFilterValue) && objSecondaryQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                                    let sUnit = objSecondaryQuery.sFilterValue.split("_")[1];

                                    objFromDate.setDateFromRest(objSecondaryQuery.mValue.sFrom);
                                    objToDate.setDateFromRest(objSecondaryQuery.mValue.sTo);
                                    objFromDate.setTimeToStartOfDay();
                                    objToDate.setTimeToStartOfDay();

                                    Ember.set(objSecondary, "_iFilterValue", Math.round(objFromDate.difference(objToDate, sUnit, true)));

                                    Ember.set(objSecondary, "_sToDate", objToDate.getDateFormat());
                                    Ember.set(objSecondary, "_sFromDate", objFromDate.getDateFormat());
                                } else if (!a24Core.isEmpty(objSecondaryQuery.sFilterValue) && objSecondaryQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                                    let sUnit = objSecondaryQuery.sFilterValue.split("_")[1];
                                    objToDate.setDateFromRest(objSecondaryQuery.mValue);
                                    objCurrentDate.setTimeToStartOfDay();
                                    objToDate.setTimeToStartOfDay();

                                    Ember.set(objSecondary, "_iFilterValue", Math.round(objCurrentDate.difference(objToDate, sUnit, true)));

                                    Ember.set(objSecondary, "_sToDate", objToDate.getDateFormat());
                                    Ember.set(objSecondary, "_sFromDate", "");
                                } else if (!a24Core.isEmpty(objSecondaryQuery.sFilterValue) && objSecondaryQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                                    let sUnit = objSecondaryQuery.sFilterValue.split("_")[1];

                                    objFromDate.setDateFromRest(objSecondaryQuery.mValue);
                                    objFromDate.setTimeToStartOfDay();
                                    objCurrentDate.setTimeToStartOfDay();

                                    Ember.set(objSecondary, "_iFilterValue", Math.round(objFromDate.difference(objCurrentDate, sUnit, true)));

                                    Ember.set(objSecondary, "_sToDate", "");
                                    Ember.set(objSecondary, "_sFromDate", objFromDate.getDateFormat());
                                } else if (!a24Core.isEmpty(objSecondaryQuery.sFilterValue) && objSecondaryQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                                    let sUnit = objSecondaryQuery.sFilterValue.split("_")[1];

                                    objFromDate.setDateFromRest(objSecondaryQuery.mValue);
                                    objFromDate.setTimeToStartOfDay();
                                    objCurrentDate.setTimeToStartOfDay();

                                    Ember.set(objSecondary, "_iFilterValue", Math.round(objCurrentDate.difference(objFromDate, sUnit, true)));

                                    Ember.set(objSecondary, "_sToDate", "");
                                    Ember.set(objSecondary, "_sFromDate", objFromDate.getDateFormat());
                                } else if (!a24Core.isEmpty(objSecondaryQuery.sFilterValue) && objSecondaryQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                                    let arrExtraOptions = objSecondaryQuery.sFilterValue.split("_");
                                    Ember.set(objSecondary, "_objBetweenOption", arrExtraOptions[1]);
                                    Ember.set(objSecondary, "_iFilterValue", arrExtraOptions[2]);
                                    Ember.set(objSecondary, "_iFilterValueTo", arrExtraOptions[3]);

                                    let iDays = parseInt(arrExtraOptions[2]);
                                    let iDaysTo = parseInt(arrExtraOptions[3]);

                                    let iHigh = iDays >= iDaysTo ? iDays : iDaysTo;
                                    let iLow = iDays < iDaysTo ? iDays : iDaysTo;
                                    if (arrExtraOptions[1] === "ago") {
                                        objFromDate.setTimeToStartOfDay();
                                        objFromDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iHigh);
                                        objToDate.setTimeToEndOfDay();
                                        objToDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iLow);
                                    } else if (arrExtraOptions[1] === "fromToday") {
                                        objFromDate.setTimeToStartOfDay();
                                        objFromDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iLow);
                                        objToDate.setTimeToEndOfDay();
                                        objToDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iHigh);
                                    }

                                    Ember.set(objSecondary, "_sToDate", objToDate.getDateFormat());
                                    Ember.set(objSecondary, "_sFromDate", objFromDate.getDateFormat());
                                } else if (!a24Core.isEmpty(objSecondaryQuery.sFilterValue) && objSecondaryQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_EMPTY.backend"), 0) === 0) {

                                    Ember.set(objSecondary, "_sToDate", "");
                                    Ember.set(objSecondary, "_sFromDate", momentHelper.timeStampTimezoneFormat(0, "utc", a24Constants.DATE_FORMAT));
                                } else if (objSecondaryQuery.bFromAndTo || objSecondaryQuery.bNotWithinNext || objSecondaryQuery.bNotWithinLast) {
                                    objFromDate.setDateFromRest(objSecondaryQuery.mValue.sFrom);
                                    objToDate.setDateFromRest(objSecondaryQuery.mValue.sTo);
                                    if (objSecondaryQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || objSecondaryQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || objSecondaryQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || objSecondaryQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || objSecondaryQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                                        objFromDate.setTimeToStartOfDay();
                                        objToDate.setTimeToStartOfDay();
                                        Ember.set(objSecondary, "_iFilterValue", Math.round(objFromDate.difference(objToDate, a24Constants.MOMENT_TIME_DAYS), true));
                                    }
                                    Ember.set(objSecondary, "_sToDate", objToDate.getDateFormat());
                                    Ember.set(objSecondary, "_sFromDate", objFromDate.getDateFormat());
                                } else if (objSecondaryQuery.bFrom) {
                                    Ember.set(objSecondary, "_sToDate", "");
                                    objCurrentDate.setDateFromRest(objSecondaryQuery.mValue);
                                    Ember.set(objSecondary, "_sFromDate", objCurrentDate.getDateFormat());
                                } else if (objSecondaryQuery.bTo) {
                                    Ember.set(objSecondary, "_sFromDate", "");
                                    objCurrentDate.setDateFromRest(objSecondaryQuery.mValue);
                                    Ember.set(objSecondary, "_sToDate", objCurrentDate.getDateFormat());
                                }
                                Ember.set(objSecondary, "_bInternalValueChange", false);

                                Ember.set(objSecondary, "_objFilterValue", {
                                    sValue: objSecondaryQuery.sFilterValue
                                });
                            }
                        }
                    }
                }
            }
        },
        getDateFromAndToValues: function (sDateOption, iDays, iDaysTo) {
            var sFromDate = "";
            var sToDate = "";
            var sFromTime = "";
            var sToTime = "";
            var bCustomRange = false;
            var bNotInset = false;

            //This code can be moved out to a different function later if we want to reuse it. For now it will be
            //kept in this component
            var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_TODAY.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_TODAY.backend")) {
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_YESTERDAY.backend")) {
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_WEEK_TODAY.backend")) {
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_WEEK_TO_FRI.backend")) {
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 2);
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_WEEK_TO_SUN.backend")) {
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_LAST_7_DAYS.backend")) {
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 7);
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_LAST_WEEK_TO_FRI.backend")) {
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_WEEKS"), 1);
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 2);
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_LAST_WEEK_TO_SUN.backend")) {
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_WEEKS"), 1);
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_LAST_14_DAYS.backend")) {
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 14);
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_MONTH_TO_TODAY.backend")) {
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_MONTH_TO_END.backend")) {
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_LAST_30_DAYS.backend")) {
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 30);
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_THIS_LAST_MONTH.backend")) {
                objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), 1);
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
                objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (!a24Core.isEmpty(sDateOption) && (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0)) {
                let sUnit = sDateOption.split("_")[1];
                if (a24Core.isEmpty(iDays) || iDays === 0) {
                    sToDate = null;
                    sFromDate = null;
                } else {
                    if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                        objCurrentDate.setTimeToStartOfDay();
                        objCurrentDate.subtractTimeFromDate(sUnit, iDays);
                        sFromDate = objCurrentDate.getDateFormat();
                        sFromTime = objCurrentDate.getTimeFormat();
                    } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                        objCurrentDate.setTimeToStartOfDay();
                        objCurrentDate.addTimeToDate(sUnit, iDays);
                        sFromDate = objCurrentDate.getDateFormat();
                        sFromTime = objCurrentDate.getTimeFormat();
                    } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                        objCurrentDate.setTimeToEndOfDay();
                        sToDate = objCurrentDate.getDateFormat();
                        sToTime = objCurrentDate.getTimeFormat();
                        objCurrentDate.setTimeToStartOfDay();
                        objCurrentDate.subtractTimeFromDate(sUnit, iDays);
                        sFromDate = objCurrentDate.getDateFormat();
                        sFromTime = objCurrentDate.getTimeFormat();
                    } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                        objCurrentDate.setTimeToEndOfDay();
                        objCurrentDate.subtractTimeFromDate(sUnit, iDays);
                        sToDate = objCurrentDate.getDateFormat();
                        sToTime = objCurrentDate.getTimeFormat();
                    }
                }
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_REST_OF_WEEK.backend")) {
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_REST_OF_MONTH.backend")) {
                objCurrentDate.setTimeToStartOfDay();
                sFromDate = objCurrentDate.getDateFormat();
                sFromTime = objCurrentDate.getTimeFormat();
                objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                objCurrentDate.setTimeToEndOfDay();
                sToDate = objCurrentDate.getDateFormat();
                sToTime = objCurrentDate.getTimeFormat();
            } else if (!a24Core.isEmpty(sDateOption) && sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                if (a24Core.isEmpty(iDays) && a24Core.isEmpty(iDaysTo)) {
                    sToDate = null;
                    sFromDate = null;
                } else {
                    var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    let arrExtraOptions = sDateOption.split("_");
                    iDays = parseInt(iDays);
                    iDaysTo = parseInt(iDaysTo);
                    let iHigh = iDays >= iDaysTo ? iDays : iDaysTo;
                    let iLow = iDays < iDaysTo ? iDays : iDaysTo;
                    if (arrExtraOptions[1] === "ago") {
                        objFromDate.setTimeToStartOfDay();
                        objFromDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iHigh);
                        objToDate.setTimeToEndOfDay();
                        objToDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iLow);
                    } else if (arrExtraOptions[1] === "fromToday") {
                        objFromDate.setTimeToStartOfDay();
                        objFromDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iLow);
                        objToDate.setTimeToEndOfDay();
                        objToDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iHigh);
                    }
                    sFromDate = objFromDate.getDateFormat();
                    sFromTime = objFromDate.getTimeFormat();
                    sToDate = objToDate.getDateFormat();
                    sToTime = objToDate.getTimeFormat();
                }
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                if (a24Core.isEmpty(iDays) || iDays === 0) {
                    sToDate = null;
                    sFromDate = null;
                } else {
                    if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend")) {
                        objCurrentDate.setTimeToEndOfDay();
                        sToDate = objCurrentDate.getDateFormat();
                        sToTime = objCurrentDate.getTimeFormat();
                        objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iDays);
                        objCurrentDate.setTimeToStartOfDay();
                        sFromDate = objCurrentDate.getDateFormat();
                        sFromTime = objCurrentDate.getTimeFormat();
                    } else {
                        objCurrentDate.setTimeToStartOfDay();
                        sFromDate = objCurrentDate.getDateFormat();
                        sFromTime = objCurrentDate.getTimeFormat();
                        objCurrentDate.addTimeToDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), iDays);
                        objCurrentDate.setTimeToEndOfDay();
                        sToDate = objCurrentDate.getDateFormat();
                        sToTime = objCurrentDate.getTimeFormat();
                    }
                }
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend") || sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend")) {
                //We do not want to change the dates since the user could be altering a previous choice
                bCustomRange = true;
            } else if (sDateOption === this.get("_objEnumEmberLibService.dateFilterOptionsNotEmpty.DATE_FILTER_NOT_EMPTY.backend")) {
                sFromDate = momentHelper.timeStampTimezoneFormat(0, "utc", a24Constants.DATE_FORMAT);
                sFromTime = "00:00:00";
            } else if ("null" !== sDateOption) {
                //null is also valid options to filter on empty dates
                bNotInset = true;
            }

            return {
                sFromDate: sFromDate,
                sToDate: sToDate,
                sFromTime: sFromTime,
                sToTime: sToTime,
                bCustomRange: bCustomRange,
                bNotInset: bNotInset
            };
        },
        createAdvanceTag: function (objHeaderItem, objHeadersSecondaries) {
            var arrTag = [];
            if (!a24Core.isEmpty(objHeaderItem.mFilterValue) && objHeaderItem.mFilterValue.length > 1) {
                //the first item to have operation on it would be the second item.
                var bAndPrio = false;
                if (!a24Core.isEmpty(objHeaderItem.mFilterValue[1].bA) && objHeaderItem.mFilterValue[1].bA) {
                    bAndPrio = true;
                }

                arrTag.push({
                    sPreValue: "",
                    sValue: objHeaderItem.sTitle + ": ",
                    sValueClean: objHeaderItem.sTitle + ": ",
                    sPostValue: "",
                    iIndex: -1
                });

                var arrTagItems = [];
                var iGroup = 0;
                var bNeedsBrackets = false;

                //First we group all the items based on the first operation the user chose
                for (var i = 0; i < objHeaderItem.mFilterValue.length; i++) {
                    var objTagItem = {
                        iGroup: null,
                        sTag: "",
                        bAnd: objHeaderItem.mFilterValue[i].bA
                    };
                    if (i === 0) {
                        iGroup = iGroup + 1;
                        objTagItem.iGroup = iGroup;
                    } else if (bAndPrio && objHeaderItem.mFilterValue[i].bA || !bAndPrio && objHeaderItem.mFilterValue[i].bO) {
                        if (a24Core.isEmpty(arrTagItems[i - 1].iGroup)) {
                            iGroup = iGroup + 1;
                            objTagItem.iGroup = iGroup;
                            arrTagItems[i - 1].iGroup = iGroup;
                        } else {
                            objTagItem.iGroup = arrTagItems[i - 1].iGroup;
                        }
                    } else {
                        bNeedsBrackets = true;
                    }

                    let objSingleTagItem = this._createAdvanceTagSingleItem(objHeaderItem, objHeaderItem.mFilterValue[i], objHeadersSecondaries, true);
                    objTagItem.sTag = objSingleTagItem.sTag;
                    objTagItem.sTagClean = objSingleTagItem.sTagClean;

                    arrTagItems.push(objTagItem);
                }

                var sAnd = this.get("_objStringsService").getString("and").toUpperCase();
                var sOr = this.get("_objStringsService").getString("or").toUpperCase();

                //Then we loop over the groups and determine where we need to add brackets.
                for (var k = 0; k < arrTagItems.length; k++) {
                    if (a24Core.isEmpty(arrTagItems[k].iGroup)) {
                        arrTag.push({
                            sPreValue: Ember.String.htmlSafe(" <b>" + (arrTagItems[k].bAnd ? sAnd : sOr) + "</b> "),
                            sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                            sValueClean: arrTagItems[k].sTagClean,
                            bAnd: arrTagItems[k].bAnd,
                            sPostValue: "",
                            iIndex: k
                        });
                    } else if (k === 0) {
                        if (bNeedsBrackets && arrTagItems[k].iGroup === arrTagItems[k + 1].iGroup) {
                            arrTag.push({
                                sPreValue: Ember.String.htmlSafe("<b>(</b>"),
                                sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                                sValueClean: arrTagItems[k].sTagClean,
                                bAnd: arrTagItems[k].bAnd,
                                sPostValue: "",
                                iIndex: k
                            });
                        } else {
                            arrTag.push({
                                sPreValue: "",
                                sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                                sValueClean: arrTagItems[k].sTagClean,
                                bAnd: arrTagItems[k].bAnd,
                                sPostValue: "",
                                iIndex: k
                            });
                        }
                    } else if (k === arrTagItems.length - 1) {
                        let objTaggy = {
                            sPreValue: Ember.String.htmlSafe(" <b>" + (arrTagItems[k].bAnd ? sAnd : sOr) + "</b> "),
                            sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                            sValueClean: arrTagItems[k].sTagClean,
                            bAnd: arrTagItems[k].bAnd,
                            sPostValue: "",
                            iIndex: k
                        };

                        if (bNeedsBrackets && arrTagItems[k].iGroup === arrTagItems[k - 1].iGroup) {
                            objTaggy.sPostValue = Ember.String.htmlSafe("<b>)</b>");
                        }

                        arrTag.push(objTaggy);
                    } else {
                        if (arrTagItems[k].iGroup === arrTagItems[k - 1].iGroup && arrTagItems[k].iGroup === arrTagItems[k + 1].iGroup) {
                            arrTag.push({
                                sPreValue: Ember.String.htmlSafe(" <b>" + (arrTagItems[k].bAnd ? sAnd : sOr) + "</b> "),
                                sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                                sValueClean: arrTagItems[k].sTagClean,
                                bAnd: arrTagItems[k].bAnd,
                                sPostValue: "",
                                iIndex: k
                            });
                        } else if (arrTagItems[k].iGroup === arrTagItems[k - 1].iGroup) {
                            arrTag.push({
                                sPreValue: Ember.String.htmlSafe(" <b>" + (arrTagItems[k].bAnd ? sAnd : sOr) + "</b> "),
                                sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                                sValueClean: arrTagItems[k].sTagClean,
                                bAnd: arrTagItems[k].bAnd,
                                sPostValue: Ember.String.htmlSafe("<b>)</b>"),
                                iIndex: k
                            });
                        } else if (arrTagItems[k].iGroup === arrTagItems[k + 1].iGroup) {
                            arrTag.push({
                                sPreValue: Ember.String.htmlSafe(" <b>" + (arrTagItems[k].bAnd ? sAnd : sOr) + " (</b>"),
                                sValue: Ember.String.htmlSafe(arrTagItems[k].sTag),
                                sValueClean: arrTagItems[k].sTagClean,
                                bAnd: arrTagItems[k].bAnd,
                                sPostValue: "",
                                iIndex: k
                            });
                        }
                    }
                }
            } else if (!a24Core.isEmpty(objHeaderItem.mFilterValue)) {
                return {
                    sTag: this._createAdvanceTagSingleItem(objHeaderItem, objHeaderItem.mFilterValue[0], objHeadersSecondaries, false).sTag
                };
            }

            return { arrTag: arrTag };
        },
        _createAdvanceTagSingleItem: function (objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle) {
            var sTag = "";
            var sTagSimpleClean = null;
            var bTagSimpleClean = false;
            if (objHeaderItem.sFilterType === "custom") {
                let objCustomTag = objHeaderItem.objFilterCustomService.funcFilterCustomTag(objHeaderItem, objQuery, objHeadersSecondaries, bSkipTitle);
                sTag = objCustomTag.sTag;
                if (!a24Core.isEmpty(objCustomTag.sTagSimple)) {
                    bTagSimpleClean = true;
                    sTagSimpleClean = objCustomTag.sTagSimple;
                }
            } else {
                let objTag = this._createAdvanceTagSingleProp(objHeaderItem, objQuery, bSkipTitle, false);
                sTag = objTag.sTag;
                if (a24Core.isEmpty(objTag.sTagSimple)) {
                    sTagSimpleClean = objTag.sTag;
                } else {
                    bTagSimpleClean = true;
                    sTagSimpleClean = objTag.sTagSimple;
                }

                if (!a24Core.isEmpty(objQuery.arSec)) {
                    var arrSecondaries = Ember.get(objHeadersSecondaries, "arrFilterSecondary_" + objHeaderItem.sProperty);
                    var objFilterItem = null;
                    for (var i = 0; i < objQuery.arSec.length; i++) {
                        objFilterItem = null;
                        for (var j = 0; j < arrSecondaries.length; j++) {
                            if (objQuery.arSec[i].sProp === arrSecondaries[j].sProperty) {
                                objFilterItem = arrSecondaries[j];
                                break;
                            }
                        }
                        let objTagSec = this._createAdvanceTagSingleProp(objFilterItem, objQuery.arSec[i], false, true);
                        sTag += ", " + objTagSec.sTag;
                        if (a24Core.isEmpty(objTagSec.sTagSimple)) {
                            sTagSimpleClean += ", " + objTagSec.sTag;
                        } else {
                            bTagSimpleClean = true;
                            sTagSimpleClean += ", " + objTagSec.sTagSimple;
                        }
                    }
                }
            }

            let objReturn = {
                sTag: sTag,
                sTagClean: sTag
            };
            if (bSkipTitle) {
                objReturn.sTag = "<span class='a24-grey-text-darken-3'>" + sTag + "</span>";
            }
            if (bTagSimpleClean) {
                objReturn.sTagSimpleClean = sTagSimpleClean;
            }
            return objReturn;
        },
        _createAdvanceTagSingleProp: function (objFilterItem, objQuery, bSkipTitle, bSecondary) {
            var sHeaderFilterTag = objFilterItem.sTitle + " ";
            var sHeaderFilterTagSimple = objFilterItem.sTitle + " ";
            var bHeaderFilterTagSimple = false;
            var sMiddleIsText = "";
            var sMiddleInText = "";
            var objFromDate = null;
            var objToDate = null;

            if (!bSecondary) {
                if (objQuery.bDelimitedNot) {
                    sMiddleIsText = this.get("_objStringsService").getString("isNot") + " ";
                    sMiddleInText = this.get("_objStringsService").getString("notIn") + " ";
                } else {
                    sMiddleIsText = this.get("_objStringsService").getString("is") + " ";
                    sMiddleInText = this.get("_objStringsService").getString("in") + " ";
                }
            }

            if (bSkipTitle) {
                sHeaderFilterTag = "";
                sHeaderFilterTagSimple = "";
            } else {
                sMiddleIsText = sMiddleIsText.toLowerCase();
                sMiddleInText = sMiddleInText.toLowerCase();
            }

            if (objQuery.mValue === "null") {
                if (bSkipTitle) {
                    sHeaderFilterTag += this.get("_objStringsService").getString("isEmpty");
                } else {
                    sHeaderFilterTag += this.get("_objStringsService").getString("isEmpty").toLowerCase();
                }
            } else if ("text" === objFilterItem.sFilterType) {
                var sTextPart = "";
                if (objQuery.bStartWith) {
                    sTextPart = this.get("_objStringsService").getString("startsWith");
                } else if (objQuery.bEndsWith) {
                    sTextPart = this.get("_objStringsService").getString("endsWith");
                } else if (objQuery.bLike) {
                    sTextPart = this.get("_objStringsService").getString("contains");
                } else if (objQuery.bDelimitedNot) {
                    sTextPart = this.get("_objStringsService").getString("isNot");
                } else {
                    sTextPart = this.get("_objStringsService").getString("is");
                }
                if (!bSkipTitle) {
                    sTextPart = sTextPart.toLowerCase();
                }
                sHeaderFilterTag += sTextPart + " " + objQuery.mValue;
            } else if ("bool" === objFilterItem.sFilterType) {
                var sTagValue = null;
                for (var j = 0; j < objFilterItem.arrMappingData.length; j++) {
                    if (objFilterItem.arrMappingData[j].sValue === objQuery.mValue + "") {
                        sTagValue = objFilterItem.arrMappingData[j].sTitle;
                        break;
                    }
                }
                sHeaderFilterTag += sMiddleIsText + sTagValue;
            } else if ("dropdown" === objFilterItem.sFilterType) {
                var sDropdownTagValue = null;
                for (var k = 0; k < objFilterItem.arrMappingData.length; k++) {
                    if (objFilterItem.arrMappingData[k].sValue === objQuery.mValue) {
                        sDropdownTagValue = objFilterItem.arrMappingData[k].sTitle;
                        break;
                    }
                }
                sHeaderFilterTag += sMiddleIsText + sDropdownTagValue;
            } else if ("dropdownMulti" === objFilterItem.sFilterType) {
                var sDropMultiTagValue = null;

                var arrFilterItems = objQuery.mValue.split("|");
                if (arrFilterItems.length === 1) {
                    for (var i = 0; i < objFilterItem.arrMappingData.length; i++) {
                        if (objFilterItem.arrMappingData[i].sValue === arrFilterItems[0]) {
                            sDropMultiTagValue = objFilterItem.arrMappingData[i].sTitle;
                            break;
                        }
                    }
                } else {
                    sDropMultiTagValue = this.get("_objStringsService").getString("multiple");

                    let arrValues = [];
                    for (var m = 0; m < arrFilterItems.length; m++) {
                        for (var n = 0; n < objFilterItem.arrMappingData.length; n++) {
                            if (objFilterItem.arrMappingData[n].sValue === arrFilterItems[m]) {
                                arrValues.push(objFilterItem.arrMappingData[n].sTitle);
                                break;
                            }
                        }
                    }
                    arrValues = arrValues.sort(function (sItemA, sItemB) {
                        if (sItemA < sItemB) {
                            return -1;
                        } else if (sItemA > sItemB) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    sHeaderFilterTagSimple += sMiddleIsText + "(" + arrValues.join(", ") + ")";
                    bHeaderFilterTagSimple = true;
                }

                sHeaderFilterTag += sMiddleIsText + sDropMultiTagValue;
            } else if ("date" === objFilterItem.sFilterType) {
                if (objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                    sHeaderFilterTag = `${this.get("_objStringsService").getString("dateFilterExpiryNotExpired")}`;
                } else if (!a24Core.isEmpty(objQuery.sFilterValue) && objQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                    let sUnit = objQuery.sFilterValue.split("_")[1];

                    sHeaderFilterTag = `${this.get("_objStringsService").getString("dateFilterExpiryNotExpiredGrace")} ${objQuery.iDays} ${sUnit}`;
                } else if (!a24Core.isEmpty(objQuery.sFilterValue) && objQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                    let sUnit = objQuery.sFilterValue.split("_")[1];

                    sHeaderFilterTag = `${this.get("_objStringsService").getString("dateFilterExpiryWillNotExpire")} ${objQuery.iDays} ${sUnit}`;
                } else if (!a24Core.isEmpty(objQuery.sFilterValue) && objQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                    let sUnit = objQuery.sFilterValue.split("_")[1];

                    sHeaderFilterTag += `${this.get("_objStringsService").getString("dateFilterObtainedWithinLast")} ${objQuery.iDays} ${sUnit} ${this.get("_objStringsService").getString("ago").toLowerCase()}`;
                } else if (!a24Core.isEmpty(objQuery.sFilterValue) && objQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                    let sUnit = objQuery.sFilterValue.split("_")[1];

                    sHeaderFilterTag += `${this.get("_objStringsService").getString("dateFilterObtainedAtLeast")} ${objQuery.iDays} ${sUnit} ${this.get("_objStringsService").getString("ago").toLowerCase()}`;
                } else if (objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                    objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objFromDate.setDateFromBrowser(objQuery.mValue, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));
                    let sFromString = "";

                    if (objFilterItem.bFilterAllowTime) {
                        sFromString = objFromDate.getDateTimeFormat();
                    } else {
                        sFromString = objFromDate.getDateFormat();
                    }

                    sHeaderFilterTag = `${this.get("_objStringsService").getString("dateFilterExpiryAfter")} ${sFromString}`;
                } else if (!a24Core.isEmpty(objQuery.sFilterValue) && objQuery.sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                    let arrExtraOptions = objQuery.sFilterValue.split("_");
                    sHeaderFilterTag += `${sMiddleIsText} between ${arrExtraOptions[2]} and ${arrExtraOptions[3]} days ${this.get("_objStringsService").getString(arrExtraOptions[1]).toLowerCase()}`;
                } else if (objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                    objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objFromDate.setDateFromBrowser(objQuery.mValue.sFrom, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));
                    objToDate.setDateFromBrowser(objQuery.mValue.sTo, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));

                    objFromDate.setTimeToStartOfDay();
                    objToDate.setTimeToStartOfDay();

                    let iDays = Math.round(objToDate.difference(objFromDate, a24Constants.MOMENT_TIME_DAYS, true));
                    sHeaderFilterTag += sMiddleIsText + " " + this.get("_objStringsService").getString(objQuery.sFilterValue) + " " + iDays + " " + this.get("_objStringsService").getString("dateDays");
                } else if (objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") || objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend")) {
                    objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    let sFromString = "";
                    let sToString = "";

                    if (objQuery.bTo) {
                        objToDate.setDateFromBrowser(objQuery.mValue, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));
                        if (objFilterItem.bFilterAllowTime) {
                            sToString = objToDate.getDateTimeFormat();
                        } else {
                            sToString = objToDate.getDateFormat();
                        }
                        sHeaderFilterTag += " " + this.get("_objStringsService").getString("to").toLowerCase() + " " + sToString;
                    } else if (objQuery.bFrom) {
                        objFromDate.setDateFromBrowser(objQuery.mValue, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));
                        if (objFilterItem.bFilterAllowTime) {
                            sFromString = objFromDate.getDateTimeFormat();
                        } else {
                            sFromString = objFromDate.getDateFormat();
                        }

                        if (objQuery.sFilterValue !== this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend") || objQuery.sProp !== "rule_output_change_date_compliant") {
                            sHeaderFilterTag += " " + this.get("_objStringsService").getString("from").toLowerCase();
                        }
                        sHeaderFilterTag += " " + sFromString;
                    } else {

                        objFromDate.setDateFromBrowser(objQuery.mValue.sFrom, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));
                        objToDate.setDateFromBrowser(objQuery.mValue.sTo, this.get("_objLibConstants.DATE_FORMAT_ISO_FORMAT"));
                        if (objFilterItem.bFilterAllowTime) {
                            sFromString = objFromDate.getDateTimeFormat();
                            sToString = objToDate.getDateTimeFormat();
                        } else {
                            sFromString = objFromDate.getDateFormat();
                            sToString = objToDate.getDateFormat();
                        }
                        if (sFromString.indexOf(":") !== 0 && sToString.indexOf(":") !== 0) {
                            sHeaderFilterTag += sMiddleInText + this.get("_objEmberLibStringsService").getString("between").toLowerCase() + " " + sFromString + " " + this.get("_objStringsService").getString("and").toLowerCase() + " " + sToString;
                        } else {
                            sHeaderFilterTag += sMiddleInText + this.get("_objEmberLibStringsService").getString("between").toLowerCase() + " " + objFromDate.getDateFormat() + " " + this.get("_objStringsService").getString("and").toLowerCase() + " " + objToDate.getDateFormat();
                        }
                    }
                } else if (objQuery.sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsNotEmpty.DATE_FILTER_NOT_EMPTY.backend")) {

                    sHeaderFilterTag += sMiddleIsText + this.get("_objStringsService").getString("dateFilterNotEmpty").toLowerCase();
                } else {
                    sHeaderFilterTag += sMiddleInText + this.get("_objStringsService").getString(objQuery.sFilterValue);
                }
            } else if ("int" === objFilterItem.sFilterType) {
                if (objQuery.bBefore) {
                    sMiddleIsText = this.get("_objStringsService").getString("smallerThan").toLowerCase() + " ";
                }
                if (objQuery.bAfter) {
                    sMiddleIsText = this.get("_objStringsService").getString("biggerThan").toLowerCase() + " ";
                }

                sHeaderFilterTag += sMiddleIsText + objQuery.mValue;
            } else if ("suggest" === objFilterItem.sFilterType) {
                sHeaderFilterTag += sMiddleIsText + objQuery.sFilterValue;
            }

            let objReturn = {
                sTag: sHeaderFilterTag
            };
            if (bHeaderFilterTagSimple) {
                objReturn.sTagSimple = sHeaderFilterTagSimple;
            }

            return objReturn;
        },
        buildAdvanceFilter: function (objSelectedFilterOption, bAndOrQuestionAnd, bAndOrQuestionOr, objSelectedQueryType, objHeaderSecondaries, iAdvanceFilterIndex) {
            var objQuery = this.createQueryAndTag(objSelectedFilterOption, false, objHeaderSecondaries, objSelectedQueryType).objQuery;
            if (bAndOrQuestionAnd) {
                objQuery.bA = bAndOrQuestionAnd;
            }
            if (bAndOrQuestionOr) {
                objQuery.bO = bAndOrQuestionOr;
            }
            if (!a24Core.isEmpty(objSelectedQueryType) && Ember.get(objSelectedQueryType, "sValue") === this.get("_objEnumEmberLibService.filterOptionsType.IS_NOT.backend")) {
                objQuery.bDelimitedNot = true;
            }
            //Special case for date, we need this value to build the correct tag and also select the correct date range if
            //value for instance is "Last 14 days"
            if ("date" === objSelectedFilterOption.sFilterType && objSelectedFilterOption.sFilterValue !== "null") {
                objQuery.sFilterValue = objSelectedFilterOption.sFilterValue;
            } else if ("suggest" === objSelectedFilterOption.sFilterType && objSelectedFilterOption.sFilterValue !== "null") {
                objQuery.sFilterValue = objSelectedFilterOption.sFilterValue.split("||")[1];
            }

            if (objSelectedFilterOption.bFilterSecondary && !objQuery.bDelimitedNot) {
                var arrSecondaries = Ember.get(objHeaderSecondaries, "arrFilterSecondary_" + objSelectedFilterOption.sProperty);
                if (a24Core.isEmpty(arrSecondaries)) {
                    //eslint-disable-next-line no-console
                    console.error("Datagrid filter config error, expected [" + objSelectedFilterOption.sProperty + "] to have a " + "non empty arrFilterSecondary_" + objSelectedFilterOption.sProperty + " property set on the config");
                }
                for (var i = 0; i < arrSecondaries.length; i++) {
                    var objSecondary = arrSecondaries[i];
                    var objSecondaryQuery = null;
                    if (Ember.get(objSecondary, "_bEmptyEntriesOnly")) {
                        objSecondaryQuery = {
                            sProp: Ember.get(objSecondary, "sProperty"),
                            mValue: "null"
                        };
                    } else if (Ember.get(objSecondary, "_bIntFilter")) {
                        if (!a24Core.isEmpty(Ember.get(objSecondary, "_objFilterValue")) && !a24Core.isEmpty(Ember.get(objSecondary, "_iFilterValue"))) {
                            objSecondaryQuery = {
                                sProp: Ember.get(objSecondary, "sProperty"),
                                mValue: Ember.get(objSecondary, "_iFilterValue")
                            };

                            if (Ember.get(objSecondary, "_objFilterValue.sValue") === this.get("_objEnumEmberLibService.intFilterOptions.BIGGER_THAN.backend")) {
                                objSecondaryQuery.bAfter = true;
                            } else if (Ember.get(objSecondary, "_objFilterValue.sValue") === this.get("_objEnumEmberLibService.intFilterOptions.SMALLER_THAN.backend")) {
                                objSecondaryQuery.bBefore = true;
                            }
                        }
                    } else if (Ember.get(objSecondary, "_bTextFilter")) {
                        if (!a24Core.isEmpty(Ember.get(objSecondary, "_sFilterValue"))) {

                            objSecondaryQuery = {
                                sProp: Ember.get(objSecondary, "sProperty"),
                                mValue: Ember.get(objSecondary, "_sFilterValue"),
                                bLike: true,
                                bEncode: true
                            };
                        }
                    } else if (Ember.get(objSecondary, "_bBoolFilter")) {
                        if (!a24Core.isEmpty(Ember.get(objSecondary, "_objFilterValue"))) {
                            objSecondaryQuery = {
                                sProp: Ember.get(objSecondary, "sProperty"),
                                mValue: Ember.get(objSecondary, "_objFilterValue").sValue === "true"
                            };
                        }
                    } else if (Ember.get(objSecondary, "_bDropDownFilter")) {
                        if (!a24Core.isEmpty(Ember.get(objSecondary, "_objFilterValue"))) {
                            objSecondaryQuery = {
                                sProp: Ember.get(objSecondary, "sProperty"),
                                mValue: Ember.get(objSecondary, "_objFilterValue").sValue,
                                bEncode: true
                            };
                        }
                    } else if (Ember.get(objSecondary, "_bDropDownMultiFilter")) {
                        var arrItems = Ember.get(objSecondary, "_arrFilterValue");
                        var arrStatuses = [];
                        if (!a24Core.isEmpty(arrItems)) {
                            for (var j = 0; j < arrItems.length; j++) {
                                arrStatuses.push(arrItems[j].sValue);
                            }
                        }
                        var sQuery = arrStatuses.join("|");
                        if (a24Core.isEmpty(sQuery.trim())) {
                            sQuery = null;
                        }
                        if (!a24Core.isEmpty(sQuery)) {
                            objSecondaryQuery = {
                                sProp: Ember.get(objSecondary, "sProperty"),
                                mValue: sQuery,
                                bDelimited: true,
                                bEncode: true
                            };
                        }
                    } else if (Ember.get(objSecondary, "_bDateFilter")) {
                        if (!a24Core.isEmpty(Ember.get(objSecondary, "_objFilterValue"))) {
                            let objDateQueryTag = this.createDateQueryAndTag(Ember.get(objSecondary, "_objFilterValue").sValue, Ember.get(objSecondary, "_sFromDate"), Ember.get(objSecondary, "_sToDate"), objSecondary, false);
                            objSecondaryQuery = objDateQueryTag.objQuery;
                            objSecondaryQuery.sProp = Ember.get(objSecondary, "sProperty");

                            //Special case for date, we need this value to build the correct tag and also select the
                            //correct date range if value for instance is "Last 14 days"
                            objSecondaryQuery.sFilterValue = Ember.get(objSecondary, "_objFilterValue").sValue;
                        }
                    }

                    if (!a24Core.isEmpty(objSecondaryQuery)) {
                        if (a24Core.isEmpty(objQuery.arSec)) {
                            objQuery.arSec = [objSecondaryQuery];
                        } else {
                            objQuery.arSec.push(objSecondaryQuery);
                        }
                    }
                }
            }

            //If both are false we know that this is the first time the user is adding filter for this property OR the
            //ser selected to reset the filter in which case both would be false aswell
            if (!a24Core.isEmpty(iAdvanceFilterIndex)) {
                Ember.set(objSelectedFilterOption, "mFilterValue." + iAdvanceFilterIndex, objQuery);
            } else if (!bAndOrQuestionAnd && !bAndOrQuestionOr) {
                Ember.set(objSelectedFilterOption, "mFilterValue", [objQuery]);
            } else {
                Ember.get(objSelectedFilterOption, "mFilterValue").pushObject(objQuery);
            }
            //Example of what the complete mFilterValue would look like, new properties have short names to save
            //space in the url.
            // [
            //     {                        --> First item wont have bA or bO, since both will always be false here
            //         bEncode: true,
            //         mValue: "T&C",
            //         arSec: [
            //             {
            //                 sProp: "version",
            //                 mValue: 23
            //             },
            //             ...
            //         ]
            //     },
            //     {
            //         bEncode: true,
            //         mValue: "Bloep",
            //         bA: true,
            //         arSec: [
            //             {
            //                 sProp: "version",
            //                 mValue: 56
            //             },
            //             ...
            //         ]
            //     }
            //     ...
            // ];

            //reset the sFilterValue for the header since it now contains its value on the mFilterValue
            Ember.set(objSelectedFilterOption, "sFilterValue", null);
            if (objSelectedFilterOption.sFilterType === "custom") {
                objSelectedFilterOption.objFilterCustomService.funcFilterCustomPrimaryReset(objSelectedFilterOption);
            }
        },
        createQueryAndTag: function (objHeaderItem, bDoAdvanceCheck, objHeaderSecondaries, objQueryType) {
            if (!a24Core.isEmpty(objHeaderItem.funcFilterParseFilterValue)) {
                objHeaderItem = objHeaderItem.funcFilterParseFilterValue(objHeaderItem);
            }

            if (bDoAdvanceCheck && (objHeaderItem.bFilterSecondary || objHeaderItem.bFilterAnd || objHeaderItem.bFilterOr || objHeaderItem.sFilterType === "custom")) {
                var objTag = this.createAdvanceTag(objHeaderItem, objHeaderSecondaries);

                objTag.objQuery = objHeaderItem.mFilterValue;
                return objTag;
            }

            //Build up the filter query param based on the selected filter option. Also build up tag value for item.
            var objQueryObject = {};
            var sHeaderFilterTag = null;
            var sHeaderFilterTagSimple = null;
            var mHeaderFilterValue = objHeaderItem.sFilterValue;

            if (a24Core.isEmpty(objQueryType) && !a24Core.isEmpty(objHeaderItem.arrQueryType)) {
                objQueryType = objHeaderItem.arrQueryType[0];
            }

            if (mHeaderFilterValue === "null" || !a24Core.isEmpty(objQueryType) && objQueryType.sValue === this.get("_objLibConstants").CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY) {
                sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("isEmpty").toLowerCase();
            } else {
                if ("text" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;

                    // Empty will be the defualt setting of contains
                    if (a24Core.isEmpty(objQueryType) || objQueryType.sValue === this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS")) {
                        objQueryObject.bLike = true;
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("contains").toLowerCase() + " " + mHeaderFilterValue;
                    } else if (objQueryType.sValue === this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS")) {
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + mHeaderFilterValue;
                    } else if (objQueryType.sValue === this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_IS_NOT")) {
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("isNot").toLowerCase() + " " + mHeaderFilterValue;
                    } else if (objQueryType.sValue === this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_STARTS_WITH")) {
                        objQueryObject.bStartWith = true;
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("startsWith").toLowerCase() + " " + mHeaderFilterValue;
                    } else if (objQueryType.sValue === this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_ENDS_WITH")) {
                        objQueryObject.bEndsWith = true;
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("endsWith").toLowerCase() + " " + mHeaderFilterValue;
                    }
                } else if ("bool" === objHeaderItem.sFilterType) {
                    var sTagValue = null;
                    for (var j = 0; j < objHeaderItem.arrMappingData.length; j++) {
                        if (objHeaderItem.arrMappingData[j].sValue === mHeaderFilterValue) {
                            sTagValue = objHeaderItem.arrMappingData[j].sTitle;
                            break;
                        }
                    }
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + sTagValue;
                    mHeaderFilterValue = mHeaderFilterValue === "true";
                } else if ("dropdown" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    var sDropdownTagValue = null;
                    for (var k = 0; k < objHeaderItem.arrMappingData.length; k++) {
                        if (objHeaderItem.arrMappingData[k].sValue === mHeaderFilterValue) {
                            sDropdownTagValue = objHeaderItem.arrMappingData[k].sTitle;
                            break;
                        }
                    }
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + sDropdownTagValue;
                } else if ("dropdownMulti" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    objQueryObject.bDelimited = true;

                    var sDropMultiTagValue = null;

                    var arrFilterItems = mHeaderFilterValue.split("|");
                    if (arrFilterItems.length === 1) {
                        for (var i = 0; i < objHeaderItem.arrMappingData.length; i++) {
                            if (objHeaderItem.arrMappingData[i].sValue === arrFilterItems[0]) {
                                sDropMultiTagValue = objHeaderItem.arrMappingData[i].sTitle;
                                break;
                            }
                        }
                    } else {
                        sDropMultiTagValue = this.get("_objStringsService").getString("multiple");
                        let arrValues = [];
                        for (var m = 0; m < arrFilterItems.length; m++) {
                            for (var n = 0; n < objHeaderItem.arrMappingData.length; n++) {
                                if (objHeaderItem.arrMappingData[n].sValue === arrFilterItems[m]) {
                                    arrValues.push(objHeaderItem.arrMappingData[n].sTitle);
                                    break;
                                }
                            }
                        }
                        arrValues = arrValues.sort(function (sItemA, sItemB) {
                            if (sItemA < sItemB) {
                                return -1;
                            } else if (sItemA > sItemB) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });
                        sHeaderFilterTagSimple = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + arrValues.join(", ");
                    }

                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + sDropMultiTagValue;
                } else if ("date" === objHeaderItem.sFilterType) {
                    let objDateQueryTag = this.createDateQueryAndTag(objHeaderItem.sFilterValue, objHeaderItem.sFilterFromValue, objHeaderItem.sFilterToValue, objHeaderItem, false);
                    objQueryObject = objDateQueryTag.objQuery;
                    mHeaderFilterValue = objDateQueryTag.objQuery.mValue;
                    sHeaderFilterTag = objDateQueryTag.sTag;
                } else if ("custom" === objHeaderItem.sFilterType) {
                    //here we know we are dealing with a advance filter so only need to return the objQuery
                    return {
                        objQuery: objHeaderItem.objFilterCustomService.funcFilterCustomPrimaryQuery(objHeaderItem, objHeaderSecondaries)
                    };
                } else if ("suggest" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    var arrSuggestItems = objHeaderItem.sFilterValue.split("||"); //double pipe for safety.
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + arrSuggestItems[1];
                    mHeaderFilterValue = arrSuggestItems[0];
                }
            }
            objQueryObject.mValue = mHeaderFilterValue;

            let objReturn = {
                objQuery: objQueryObject,
                sTag: sHeaderFilterTag
            };
            if (!a24Core.isEmpty(sHeaderFilterTagSimple)) {
                objReturn.sTagSimple = sHeaderFilterTagSimple;
            }

            return objReturn;
        },
        createDateQueryAndTag: function (sFilterValue, sFrom, sTo, objHeaderItem, bRestFormat) {
            let sHeaderFilterTag = null;
            let objQueryObject = null;
            var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
            var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

            let iDays = 0;
            let iDaysTo = 0;
            let sUnit = "";
            if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                sUnit = sFilterValue.split("_")[1];
                objFromDate.setTimeToStartOfDay();
                objToDate.setTimeToStartOfDay();

                if (bRestFormat) {
                    objFromDate.setDateFromRest(sFrom);
                } else {
                    objFromDate.setDateFromBrowser(sFrom, this.get("_objLibConstants.DATE_FORMAT"));
                }
                iDays = Math.round(objToDate.difference(objFromDate, sUnit, true));
            } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                sUnit = sFilterValue.split("_")[1];
                objFromDate.setTimeToStartOfDay();
                objToDate.setTimeToStartOfDay();

                if (bRestFormat) {
                    objFromDate.setDateFromRest(sFrom);
                } else {
                    objFromDate.setDateFromBrowser(sFrom, this.get("_objLibConstants.DATE_FORMAT"));
                }

                iDays = Math.round(objFromDate.difference(objToDate, sUnit, true));
            } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                sUnit = sFilterValue.split("_")[1];
                objFromDate.setTimeToStartOfDay();
                objToDate.setTimeToStartOfDay();

                if (bRestFormat) {
                    objFromDate.setDateFromRest(sFrom);
                    objToDate.setDateFromRest(sTo);
                } else {
                    objFromDate.setDateFromBrowser(sFrom, this.get("_objLibConstants.DATE_FORMAT"));
                    objToDate.setDateFromBrowser(sTo, this.get("_objLibConstants.DATE_FORMAT"));
                }

                iDays = Math.round(objToDate.difference(objFromDate, sUnit, true));
            } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                sUnit = sFilterValue.split("_")[1];
                objFromDate.setTimeToStartOfDay();
                objToDate.setTimeToStartOfDay();

                if (bRestFormat) {
                    objToDate.setDateFromRest(sTo);
                } else {
                    objToDate.setDateFromBrowser(sTo, this.get("_objLibConstants.DATE_FORMAT"));
                }

                iDays = Math.round(objFromDate.difference(objToDate, sUnit, true));
            } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                let arrExtraOptions = sFilterValue.split("_");
                iDays = arrExtraOptions[2];
                iDaysTo = arrExtraOptions[3];
            } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                if (bRestFormat) {
                    objFromDate.setDateFromRest(sFrom);
                    objToDate.setDateFromRest(sTo);
                } else {
                    objFromDate.setDateFromBrowser(sFrom, this.get("_objLibConstants.DATE_FORMAT"));
                    objToDate.setDateFromBrowser(sTo, this.get("_objLibConstants.DATE_FORMAT"));
                }

                objFromDate.setTimeToStartOfDay();
                objToDate.setTimeToStartOfDay();
                iDays = Math.round(objToDate.difference(objFromDate, a24Constants.MOMENT_TIME_DAYS, true));
            }

            let sFromValue = sFrom;
            let sToValue = sTo;
            let bChangedDate = false;
            let objToAndFromDate = this.getDateFromAndToValues(sFilterValue, iDays, iDaysTo);
            if (!objToAndFromDate.bCustomRange) {
                bChangedDate = true;
                if (objHeaderItem.bFilterAllowTime) {
                    sFromValue = objToAndFromDate.sFromDate + " " + objToAndFromDate.sFromTime;
                    sToValue = objToAndFromDate.sToDate + " " + objToAndFromDate.sToTime;
                } else {
                    sFromValue = objToAndFromDate.sFromDate;
                    sToValue = objToAndFromDate.sToDate;
                }
            }
            if (bRestFormat && !bChangedDate) {
                //When dealing with the value from rest we assume that the time is
                //already what is should be e.g start/end of day or custom time
                if (!a24Core.isEmpty(sFromValue)) {
                    objFromDate.setDateFromRest(sFromValue);
                }
                if (!a24Core.isEmpty(sToValue)) {
                    objToDate.setDateFromRest(sToValue);
                }
            } else {
                if (!a24Core.isEmpty(sFromValue)) {
                    if (objHeaderItem.bFilterAllowTime) {
                        objFromDate.setDateFromBrowser(sFromValue, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                    } else {
                        objFromDate.setDateFromBrowser(sFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objFromDate.setTimeToStartOfDay();
                    }
                }
                if (!a24Core.isEmpty(sToValue)) {
                    if (objHeaderItem.bFilterAllowTime) {
                        objToDate.setDateFromBrowser(sToValue, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                    } else {
                        objToDate.setDateFromBrowser(sToValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objToDate.setTimeToEndOfDay();
                    }
                }
            }

            if (!a24Core.isEmpty(sFromValue) && !a24Core.isEmpty(sToValue)) {
                //If both to and from dates have value we need to create a bFromAndTo query, so we set the
                //from date time to the beginning of that day and the to date time to the end of that day.
                objQueryObject = {
                    bEncode: true
                };
                if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                    objQueryObject.bFromAndTo = true;
                    objQueryObject.iDays = iDays;
                } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                    objQueryObject = {
                        bFromAndTo: true,
                        iDays: iDays,
                        iDaysTo: iDaysTo,
                        bEncode: true
                    };
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend")) {
                    objQueryObject.bNotWithinLast = true;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                    objQueryObject.bNotWithinNext = true;
                } else {
                    objQueryObject.bFromAndTo = true;
                }

                var objFromAndTo = {};

                if (objHeaderItem.bTimeless === true) {
                    objFromAndTo.sFrom = objFromDate.getDateFormat();
                    objFromAndTo.sTo = objToDate.getDateFormat();
                } else {
                    objFromAndTo.sFrom = objFromDate.getDateStringForRest();
                    objFromAndTo.sTo = objToDate.getDateStringForRest();
                }

                objQueryObject.mValue = objFromAndTo;

                if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                    sHeaderFilterTag = `${objHeaderItem.sTitle} ${this.get("_objStringsService").getString("dateFilterObtainedWithinLast")} ${iDays} ${sUnit} ${this.get("_objStringsService").getString("ago").toLowerCase()}`;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                    let sFromString = "";

                    if (objHeaderItem.bFilterAllowTime) {
                        sFromString = objFromDate.getDateTimeFormat();
                    } else {
                        sFromString = objFromDate.getDateFormat();
                    }

                    sHeaderFilterTag = `${objHeaderItem.sTitle} ${this.get("_objStringsService").getString("dateFilterExpiryAfter")} ${sFromString}`;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                    sHeaderFilterTag = `${objHeaderItem.sTitle} ${this.get("_objStringsService").getString("dateFilterExpiryNotExpired")}`;
                } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                    let arrExtraOptions = sFilterValue.split("_");
                    sHeaderFilterTag = `${objHeaderItem.sTitle} is between ${iDays} and ${iDaysTo} days ${this.get("_objStringsService").getString(arrExtraOptions[1])}`;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + this.get("_objStringsService").getString(sFilterValue) + " " + iDays + " " + this.get("_objStringsService").getString("dateDays");
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend")) {
                    if (objHeaderItem.bFilterAllowTime) {
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("in").toLowerCase() + " " + this.get("_objEmberLibStringsService").getString("between").toLowerCase() + " " + objFromDate.getDateTimeFormat() + " " + this.get("_objStringsService").getString("and").toLowerCase() + " " + objToDate.getDateTimeFormat();
                    } else {
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("in").toLowerCase() + " " + this.get("_objEmberLibStringsService").getString("between").toLowerCase() + " " + objFromDate.getDateFormat() + " " + this.get("_objStringsService").getString("and").toLowerCase() + " " + objToDate.getDateFormat();
                    }
                } else {
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("in").toLowerCase() + " " + this.get("_objStringsService").getString(sFilterValue);
                }
            } else if (!a24Core.isEmpty(sFromValue)) {
                if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                    objQueryObject = {
                        bEncode: true,
                        bFrom: true,
                        iDays: iDays
                    };
                } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                    objQueryObject = {
                        bEncode: true,
                        bFrom: true,
                        iDays: iDays
                    };
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                    objQueryObject = {
                        bEncode: true,
                        bFrom: true
                    };
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                    objQueryObject = {
                        bEncode: true,
                        bAfter: true
                    };
                } else {
                    //If only the from date has a value then we set the time to the beginning of that day and create a
                    //bFrom query
                    objQueryObject = {
                        bEncode: true,
                        bFrom: true
                    };
                }

                if (objHeaderItem.bTimeless === true) {
                    objQueryObject.mValue = objFromDate.getDateFormat();
                } else {
                    objQueryObject.mValue = objFromDate.getDateStringForRest();
                }

                if (objHeaderItem.bFilterAllowTime) {
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + this.get("_objEmberLibStringsService").getString("after").toLowerCase() + " " + objFromDate.getDateTimeFormat();
                } else {
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + this.get("_objEmberLibStringsService").getString("after").toLowerCase() + " " + objFromDate.getDateFormat();
                }

                if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                    sHeaderFilterTag = `${this.get("_objStringsService").getString("dateFilterExpiryNotExpiredGrace")} ${iDays} ${sUnit}`;
                } else if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                    sHeaderFilterTag = `${this.get("_objStringsService").getString("dateFilterExpiryWillNotExpire")} ${iDays} ${sUnit}`;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                    sHeaderFilterTag = `${objHeaderItem.sTitle} ${this.get("_objStringsService").getString("dateFilterExpiryNotExpired")}`;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                    let sDate = "";
                    if (objHeaderItem.bFilterAllowTime) {
                        sDate = objFromDate.getDateTimeFormat();
                    } else {
                        sDate = objFromDate.getDateFormat();
                    }
                    sHeaderFilterTag = `${objHeaderItem.sTitle} ${this.get("_objStringsService").getString("dateFilterExpiryAfter")} ${sDate}`;
                } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsNotEmpty.DATE_FILTER_NOT_EMPTY.backend")) {
                    sHeaderFilterTag = this.get("_objStringsService").getTokenString("isNotEmptyTag", objHeaderItem.sTitle);
                }
            } else if (!a24Core.isEmpty(sToValue)) {
                //If only the to date has a value then we set the time to the end of that day and create a
                //bTo query
                objQueryObject = {
                    bEncode: true,
                    bTo: true
                };

                if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                    objQueryObject.iDays = iDays;
                }

                if (objHeaderItem.bTimeless === true) {
                    objQueryObject.mValue = objToDate.getDateFormat();
                } else {
                    objQueryObject.mValue = objToDate.getDateStringForRest();
                }

                if (objHeaderItem.bFilterAllowTime) {
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + this.get("_objEmberLibStringsService").getString("before").toLowerCase() + " " + objToDate.getDateTimeFormat();
                } else {
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + this.get("_objEmberLibStringsService").getString("before").toLowerCase() + " " + objToDate.getDateFormat();
                }
                if (!a24Core.isEmpty(sFilterValue) && sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                    sHeaderFilterTag = `${objHeaderItem.sTitle} ${this.get("_objStringsService").getString("dateFilterObtainedAtLeast")} ${iDays} ${sUnit} ${this.get("_objStringsService").getString("ago").toLowerCase()}`;
                }
            }

            // This is the case where the primary filter is an array of objects and a date filter but there is
            // no secondary filters. This will cause the date filter to not be wrapped in a contains and thus
            // giving us undesired results. So now we set a flag to wrap it in a contains
            if (!a24Core.isEmpty(objHeaderItem.sProperty) && objHeaderItem.sProperty.indexOf("___") != -1 && !objHeaderItem.bFilterSecondary) {
                objQueryObject.bApplyContains = true;
            }

            return {
                objQuery: objQueryObject,
                sTag: sHeaderFilterTag
            };
        },
        fixupAdvanceQueryValues: function (objHeader, objHeaderSecondaries) {
            if (a24Core.isEmpty(objHeader.mFilterValue)) {
                return;
            }

            if (objHeader.sFilterType === "custom") {
                //It is up to the custom filter do fixup both primary and secondary filters in the one function.
                objHeader.objFilterCustomService.funcFilterCustomFixupQuery(objHeader, objHeaderSecondaries);
            } else {
                for (var h = objHeader.mFilterValue.length - 1; h >= 0; h--) {
                    //First we need to set the correct to and from date for dates that have a value like
                    //dateFilterYesterday since yesterday will have different dates depending when you execute it
                    //suggest input does not need any clean up on sFilterType value
                    if (objHeader.sFilterType !== "suggest" && !a24Core.isEmpty(objHeader.mFilterValue[h].sFilterValue) && objHeader.mFilterValue[h].sFilterValue !== this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") && objHeader.mFilterValue[h].sFilterValue !== this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend")) {

                        let objQueryAndTag = {};
                        if (objHeader.mFilterValue[h].sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, objCurrentDate.getDateFormat(), "", objHeader, false);
                        } else if (objHeader.mFilterValue[h].sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, objHeader.mFilterValue[h].mValue, "", objHeader, true);
                        } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].sFilterValue) && objHeader.mFilterValue[h].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            objCurrentDate.setTimeToStartOfDay();
                            let sUnit = objHeader.mFilterValue[h].sFilterValue.split("_")[1];
                            objCurrentDate.subtractTimeFromDate(sUnit, objHeader.mFilterValue[h].iDays);

                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, objCurrentDate.getDateFormat(), "", objHeader, false);
                        } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].sFilterValue) && objHeader.mFilterValue[h].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            objCurrentDate.setTimeToStartOfDay();
                            let sUnit = objHeader.mFilterValue[h].sFilterValue.split("_")[1];
                            objCurrentDate.addTimeToDate(sUnit, objHeader.mFilterValue[h].iDays);

                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, objCurrentDate.getDateFormat(), "", objHeader, false);
                        } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].sFilterValue) && objHeader.mFilterValue[h].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let sUnit = objHeader.mFilterValue[h].sFilterValue.split("_")[1];
                            objCurrentDate.setTimeToEndOfDay();
                            let sToDate = objCurrentDate.getDateFormat();
                            objCurrentDate.setTimeToStartOfDay();
                            objCurrentDate.subtractTimeFromDate(sUnit, objHeader.mFilterValue[h].iDays);
                            let sFromDate = objCurrentDate.getDateFormat();

                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, sFromDate, sToDate, objHeader, false);
                        } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].sFilterValue) && objHeader.mFilterValue[h].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                            let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            objCurrentDate.setTimeToEndOfDay();
                            let sUnit = objHeader.mFilterValue[h].sFilterValue.split("_")[1];
                            objCurrentDate.subtractTimeFromDate(sUnit, objHeader.mFilterValue[h].iDays);

                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, "", objCurrentDate.getDateFormat(), objHeader, false);
                        } else {
                            //First we do the primary item
                            objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].sFilterValue, objHeader.mFilterValue[h].mValue.sFrom, objHeader.mFilterValue[h].mValue.sTo, objHeader, true);
                        }

                        if (a24Core.isEmpty(objQueryAndTag.objQuery)) {
                            Ember.get(objHeader, "mFilterValue").removeAt(h);
                            continue;
                        } else {
                            Ember.set(objHeader, "mFilterValue." + h + ".mValue", objQueryAndTag.objQuery.mValue);
                        }
                    }

                    //Then we do the secondaries if they exist
                    if (!a24Core.isEmpty(objHeader.mFilterValue[h].arSec)) {
                        var arrSecondaries = Ember.get(objHeaderSecondaries, "arrFilterSecondary_" + objHeader.sProperty);
                        if (a24Core.isEmpty(arrSecondaries)) {
                            //eslint-disable-next-line no-console
                            console.error("Datagrid filter config error, expected [" + objHeader.sProperty + "] to have a " + "non empty arrFilterSecondary_" + objHeader.sProperty + " property set on the config");
                            break;
                        }

                        //Loop in reverse so that we can remove items from the array without index out of bounds
                        for (var x = objHeader.mFilterValue[h].arSec.length - 1; x >= 0; x--) {

                            //If we cant find the secondary filter in the secondaries array on the objHeaderSecondaries
                            //then we need to remove it from the query. This will be like a case where someone has the
                            //url bookmarked and then we updated the datagrid to not support the particular secondary
                            var objSecondaryHeader = null;
                            for (var k = 0; k < arrSecondaries.length; k++) {
                                if (arrSecondaries[k].sProperty === objHeader.mFilterValue[h].arSec[x].sProp) {
                                    objSecondaryHeader = arrSecondaries[k];
                                    break;
                                }
                            }
                            if (a24Core.isEmpty(objSecondaryHeader)) {
                                Ember.get(objHeader, "mFilterValue." + h + ".arSec").removeAt(x);
                            } else {
                                //NOTE for now only date items will have the "sFilterValue" set on the query object.
                                // For now it should be safe to also do the !== "suggest" since ember side does not support
                                // suggest on secondaries yet, but react side does.
                                if (objSecondaryHeader.sFilterType !== "suggest" && !a24Core.isEmpty(objHeader.mFilterValue[h].arSec[x].sFilterValue) && objHeader.mFilterValue[h].arSec[x].sFilterValue !== this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") && objHeader.mFilterValue[h].arSec[x].sFilterValue !== this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend")) {
                                    let objQueryAndTag = {};
                                    if (objHeader.mFilterValue[h].arSec[x].sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED.backend")) {
                                        let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, objCurrentDate.getDateFormat(), "", objSecondaryHeader, false);
                                    } else if (objHeader.mFilterValue[h].arSec[x].sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend")) {
                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, objHeader.mFilterValue[h].arSec[x].mValue, "", objSecondaryHeader, true);
                                    } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].arSec[x].sFilterValue) && objHeader.mFilterValue[h].arSec[x].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                                        let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        objCurrentDate.setTimeToStartOfDay();
                                        let sUnit = objHeader.mFilterValue[h].arSec[x].sFilterValue.split("_")[1];
                                        objCurrentDate.subtractTimeFromDate(sUnit, objHeader.mFilterValue[h].arSec[x].iDays);

                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, objCurrentDate.getDateFormat(), "", objSecondaryHeader, false);
                                    } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].arSec[x].sFilterValue) && objHeader.mFilterValue[h].arSec[x].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                                        let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        objCurrentDate.setTimeToStartOfDay();
                                        let sUnit = objHeader.mFilterValue[h].arSec[x].sFilterValue.split("_")[1];
                                        objCurrentDate.addTimeToDate(sUnit, objHeader.mFilterValue[h].arSec[x].iDays);

                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, objCurrentDate.getDateFormat(), "", objSecondaryHeader, false);
                                    } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].arSec[x].sFilterValue) && objHeader.mFilterValue[h].arSec[x].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                                        let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        let sUnit = objHeader.mFilterValue[h].arSec[x].sFilterValue.split("_")[1];
                                        objCurrentDate.setTimeToEndOfDay();
                                        let sToDate = objCurrentDate.getDateFormat();
                                        objCurrentDate.setTimeToStartOfDay();
                                        objCurrentDate.subtractTimeFromDate(sUnit, objHeader.mFilterValue[h].arSec[x].iDays);
                                        let sFromDate = objCurrentDate.getDateFormat();

                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, sFromDate, sToDate, objSecondaryHeader, false);
                                    } else if (!a24Core.isEmpty(objHeader.mFilterValue[h].arSec[x].sFilterValue) && objHeader.mFilterValue[h].arSec[x].sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                                        let objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                                        objCurrentDate.setTimeToEndOfDay();
                                        let sUnit = objHeader.mFilterValue[h].arSec[x].sFilterValue.split("_")[1];
                                        objCurrentDate.subtractTimeFromDate(sUnit, objHeader.mFilterValue[h].arSec[x].iDays);

                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, "", objCurrentDate.getDateFormat(), objSecondaryHeader, false);
                                    } else {
                                        objQueryAndTag = this.createDateQueryAndTag(objHeader.mFilterValue[h].arSec[x].sFilterValue, objHeader.mFilterValue[h].arSec[x].mValue.sFrom, objHeader.mFilterValue[h].arSec[x].mValue.sTo, objSecondaryHeader, true);
                                    }

                                    if (a24Core.isEmpty(objQueryAndTag.objQuery)) {
                                        Ember.get(objHeader, "mFilterValue." + h + ".arSec").removeAt(x);
                                    } else {
                                        Ember.set(objHeader, "mFilterValue." + h + ".arSec." + x + ".mValue", objQueryAndTag.objQuery.mValue);
                                    }
                                }
                            }
                        }

                        if (a24Core.isEmpty(objHeader.mFilterValue[h].arSec)) {
                            delete Ember.get(objHeader, "mFilterValue." + h).arSec;
                        }
                    }
                }
            }

            if (a24Core.isEmpty(objHeader.mFilterValue)) {
                Ember.set(objHeader, "mFilterValue", null);
            }
        },

        /**
         * Will update the values in the datagrid query param object with the values from the url string
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  04 Jan 2021
         *
         * @param objDatagridQueryParamsObject - The `objQueryParams` from the card datagrid container
         * @param sUrl - The query part of the url, for datagrids this is normally whatever follows `?` e.g
         *               "first_name=blah&last_name=%203453gffgg"
         * @param arrSkipProps - (Optional)A list of properties that should be ignored, thus those values will not be
         *                      transplanted from the url to the query object
         *
         * @return A new copy of objDatagridQueryParamsObject with the updated values that can now be passed into
         *    the card datagrid container to trigger the update.
         */
        transplantUrlValuesToDatagrid: function (objDatagridQueryParamsObject, sUrl, arrSkipProps) {
            let objParams = new URLSearchParams(sUrl);
            let objReturn = Ember.copy(objDatagridQueryParamsObject, true);
            if (a24Core.isEmpty(arrSkipProps)) {
                arrSkipProps = [];
            }

            for (var sProp in objReturn) {
                if (Object.prototype.hasOwnProperty.call(objReturn, sProp) && arrSkipProps.indexOf(sProp) === -1) {
                    let sValue = objParams.get(sProp); //null if not in url
                    if (sValue === null) {
                        if (!a24Core.isEmpty(objReturn[sProp].sDefault)) {
                            objReturn[sProp].sValue = objReturn[sProp].sDefault;
                        } else {
                            objReturn[sProp].sValue = "";
                        }
                    } else if (a24Core.isEmpty(sValue)) {
                        objReturn[sProp].sValue = "";
                    } else {
                        objReturn[sProp].sValue = sValue;
                    }
                }
            }

            return objReturn;
        },
        /**
         * Will generate the url part that represents the query filters for the datagrid from the objQueryParams in
         * the card datagrid container
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  05 Jan 2021
         *
         * @param objDatagridQueryParamsObject - The objQueryParams from the card datagrid container
         * @param arrSkipProps - (Optional) A list of properties that should be ignored, thus not included in the url
         *
         * @return The query part of the url e.g "first_name=blah&last_name=%203453gffgg"
         */
        getDatagridUrlQueryString: function (objDatagridQueryParamsObject, arrSkipProps) {
            let objParams = new URLSearchParams("");
            if (a24Core.isEmpty(arrSkipProps)) {
                arrSkipProps = [];
            }

            for (var sProp in objDatagridQueryParamsObject) {
                if (Object.prototype.hasOwnProperty.call(objDatagridQueryParamsObject, sProp) && arrSkipProps.indexOf(sProp) === -1) {
                    if (objDatagridQueryParamsObject[sProp].sValue !== objDatagridQueryParamsObject[sProp].sDefault) {
                        objParams.set(sProp, objDatagridQueryParamsObject[sProp].sValue);
                    }
                    //else do nothing since we dont want `empty default + empty value` in url and we also dont want
                    //`default === value` in the url
                }
            }

            return objParams.toString();
        },

        /**
         * Will generate simple version of the datagrid tags from the datagrid headers.
         * **NB** This fucntion assumes that the datagrid already generated the normal tags for each header.
         *
         * @author Ruan Naude <ruan.naude@a24group.com>
         * @since  05 Jan 2021
         *
         * @param arrHeaders - The arrHeaders from the card datagrid container objDatagridConfig
         *
         * @return An array with simple tags in the structure of:
         * [
         *     {
         *         "title": "Gender is Female"
         *     },
         *     {
         *         "title": "Skill Single is B Class, Status Verified, Years Of Experience bigger than 123",
         *     },
         *     {
         *         "title": "Skill Multi: ",
         *         "tags": [
         *             {
         *                 "tag": "Is B Class, Status Verified, Years Of Experience bigger than 123",
         *                 "isAnd": false
         *             },
         *             {
         *                 "tag": "Is Heart Surgery, Status Verified, Years Of Experience 211",
         *                 "isAnd": true
         *             }
         *         ]
         *     },
         * ]
         */
        getSimpleTags: function (arrHeaders) {
            let arrSimpleTags = [];
            for (var i = 0; i < arrHeaders.length; i++) {
                let objHeader = arrHeaders[i];
                var mValue = null;
                if (!a24Core.isEmpty(objHeader.sFilterValue)) {
                    mValue = objHeader.sFilterValue;
                } else if (!a24Core.isEmpty(objHeader.mFilterValue)) {
                    mValue = objHeader.mFilterValue;
                }
                if (!a24Core.isEmpty(objHeader.sTagValueSimple)) {
                    arrSimpleTags.push({
                        title: objHeader.sTagValueSimple,
                        property: objHeader.sProperty,
                        header: objHeader.sTitle,
                        value: mValue
                    });
                } else if (!a24Core.isEmpty(objHeader.sTagValue)) {
                    arrSimpleTags.push({
                        title: objHeader.sTagValue,
                        property: objHeader.sProperty,
                        header: objHeader.sTitle,
                        value: mValue
                    });
                } else if (!a24Core.isEmpty(objHeader.arrTagValue)) {
                    let objMultiTag = {
                        title: objHeader.arrTagValue[0].sValueClean,
                        tags: [],
                        property: objHeader.sProperty,
                        header: objHeader.sTitle,
                        value: mValue
                    };
                    for (var j = 1; j < objHeader.arrTagValue.length; j++) {
                        objMultiTag.tags.push({
                            tag: objHeader.arrTagValue[j].sValueClean,
                            isAnd: objHeader.arrTagValue[j].bAnd === true
                        });
                    }
                    arrSimpleTags.push(objMultiTag);
                }
            }
            return arrSimpleTags;
        }
    });
});