define("a24-ember-lib/components/card-datagrid-filter", ["exports", "a24-ember-lib/templates/components/card-datagrid-filter", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/mixins/input-form-element"], function (exports, _cardDatagridFilter, _didRenderChangesMixin, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _cardDatagridFilter.default,
        bDisableKeyboardShortcuts: false,
        sDatagridHeader: null,
        arrHeaderConfig: null,
        objHeaderSecondaries: null,
        objReactFilterExtraData: null,
        bHasFilterApplied: false,
        bShowShareButton: false,
        bShareableLink: false,
        bShowColumnToggles: false,
        bCustomiseColumns: false,
        bCustomiseColumnsProjection: false,
        arrAllowedHeaders: null,
        arrCustomActions: null,
        arrExtraActions: null,
        bHasReloadButton: false,
        bShowSaveAction: true,
        objSavedSearchService: null,
        sSavedSearchRef: null,
        bSavedSearchShowActions: true,
        sReactIFrameUrl: null,
        iVisibleActions: 0,
        _objUtilsService: Ember.inject.service("ss-utils"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objFilterService: Ember.inject.service("card-datagrid-filter"),
        _objStringsEmberLibService: Ember.inject.service("a24-strings-ember-lib"),
        _objEnumEmberLibService: Ember.inject.service("a24-enums-ember-lib"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objQueryParamObject: null,
        _sFilterButtonText: null,
        _sFilterHeaderText: null,
        _sFilterOptionPrompt: null,
        _sEmptyOnlyText: null,
        _sFilterAddButtonText: null,
        _sFilterDateFrom: null,
        _sFilterDateTo: null,
        _funcKeyDown: null,
        _sIsLabel: null,
        _sIsInLabel: null,
        _sFilterOptionsButtonId: null,
        _sFilterOptionsDropdownId: null,
        _sFilterOptionsContainerId: null,
        _sFilterOptionsTextId: null,
        _sFilterOptionsPopupId: null,
        _sQueryTypeOptionPrompt: null,
        _bDisplayFilterChoices: false,
        _objSelectedFilterOption: null,
        _arrCustomObservers: null,
        _arrQueryType: null,
        _objSelectedQueryType: null,
        _bShowSelectedQueryType: false,
        _bShowAddOrQuestion: false,
        _bShowSecondaryFilters: true,
        _bAndOrQuestionAnd: false,
        _bAndOrQuestionOr: false,
        _bEmptyEntriesOnly: false,
        _bCustomFilter: false,
        _bTextFilter: false,
        _bBoolFilter: false,
        _bDropDownFilter: false,
        _bDropDownMultiFilter: false,
        _bDateFilter: false,
        _bDateTimeFilter: false,
        _bSuggestFilter: true,

        _bFromTagClick: false,

        _arrDateFilterData: null,
        _sFromDate: "",
        _sToDate: "",
        _sFromTime: "",
        _sToTime: "",
        _bCheckForCustomDate: true,
        _sFilterValue: null,
        _objFilterValue: null,
        _arrFilterValue: null,
        _sFilterInputPlaceHolder: null,
        _arrFilterInputArray: null,
        _iAdvanceFilterEdit: null,
        _bDisplayAddButton: false,
        _sTagButtonStyle: ".chip",
        _bSecondariesValid: true,
        _iFilterValue: null,
        _iFilterValueTo: null,
        _objBetweenOption: null,

        _bShowReactFilterIframe: false,
        _mFilterValueReact: null,
        _bReactFilterPopupLoading: true,
        _objReactPopupPostData: null,
        _funcSortFilterOptions: null,

        _funcFilterValuesObserverCallback: function () {
            Ember.run.once(this, this.setHeaderQueryParamObjects);
        },

        _bShowSavedSearch: Ember.computed("objSavedSearchService", "sSavedSearchRef", function () {
            if (!a24Core.isEmpty(this.get("objSavedSearchService")) && !a24Core.isEmpty(this.get("sSavedSearchRef"))) {
                return true;
            }
            return false;
        }).readOnly(),
        _showSecondaryFilters: Ember.observer("_objSelectedQueryType", function () {
            //If this observer is firing it means it is advance filter and thus the Empty entries checkbox will be hidden
            //So we can manually control the _bEmptyEntriesOnly without worry about user changing it
            var bDisplaySecondaries = true;
            var bEmptyEntriesOnly = false;
            if (this.get("_objSelectedQueryType.sValue") === this.get("_objEnumEmberLibService.filterOptionsType.IS_NOT.backend")) {
                bDisplaySecondaries = false;
            }
            if (this.get("_objSelectedQueryType.sValue") === this.get("_objLibConstants").CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY) {
                bDisplaySecondaries = false;
                bEmptyEntriesOnly = true;
            }

            this.set("_bEmptyEntriesOnly", bEmptyEntriesOnly);
            this.set("_bShowSecondaryFilters", bDisplaySecondaries);
        }),
        _setDateFilterFields: Ember.observer("_objFilterValue", function () {
            if (this.get("_bDateFilter")) {
                if (a24Core.isEmpty(this.get("_objFilterValue")) || a24Core.isEmpty(this.get("_objFilterValue").sValue)) {
                    this.set("_sFromDate", "");
                    this.set("_sToDate", "");
                    //Only needed to clear these values here since the inputs wont be on screen
                    this.set("_sFromTime", "");
                    this.set("_sToTime", "");
                    return;
                }
                var sDateOption = this.get("_objFilterValue").sValue;
                var iFilterValue = this.get("_iFilterValue");
                var iFilterValueTo = this.get("_iFilterValueTo");

                this.set("_sFilterValue", sDateOption);

                let objToAndFromDate = this.get("_objFilterService").getDateFromAndToValues(sDateOption, iFilterValue, iFilterValueTo);
                if (objToAndFromDate.bCustomRange) {
                    //We do not want to change the dates since the user could be altering a previous choice
                    return;
                } else {
                    this.set("_bCheckForCustomDate", false);
                    this.set("_sFromDate", objToAndFromDate.sFromDate);
                    this.set("_sToDate", objToAndFromDate.sToDate);
                    if (this.get("_bDateTimeFilter")) {
                        Ember.run.next(() => {
                            Ember.run.schedule("afterRender", () => {
                                if (!a24Core.isEmpty(this.get("objChildren.sDateTimeFrom"))) {
                                    this.get("objChildren.sDateTimeFrom").setValueFromOutside(objToAndFromDate.sFromTime);
                                }
                                if (!a24Core.isEmpty(this.get("objChildren.sDateTimeTo"))) {
                                    this.get("objChildren.sDateTimeTo").setValueFromOutside(objToAndFromDate.sToTime);
                                }
                                this.set("_bCheckForCustomDate", true);
                            });
                        });
                    } else {
                        this.set("_bCheckForCustomDate", true);
                    }
                }
            } else if (this.get("_bBoolFilter") || this.get("_bDropDownFilter")) {
                this.set("_sFilterValue", this.get("_objFilterValue.sValue"));
            } else if (this.get("_bSuggestFilter")) {
                if (a24Core.isEmpty(this.get("_objFilterValue." + this.get("_objSelectedFilterOption.sFilterOptionsValueKey")))) {
                    this.set("_sFilterValue", "");
                } else {
                    this.set("_sFilterValue", this.get("_objFilterValue." + this.get("_objSelectedFilterOption.sFilterOptionsValueKey")) + "||" + this.get("_objFilterValue." + this.get("_objSelectedFilterOption.sFilterOptionsLabelKey")));
                }
            }
        }),
        _setMultiSelectFilterFields: Ember.observer("_arrFilterValue", function () {
            if (this.get("_bDropDownMultiFilter")) {
                this._setDisplayAddButton();
                var arrItems = this.get("_arrFilterValue");
                var arrStatuses = [];
                if (!a24Core.isEmpty(arrItems)) {
                    for (var i = 0; i < arrItems.length; i++) {
                        arrStatuses.push(arrItems[i].sValue);
                    }
                }
                var sQuery = arrStatuses.join("|");
                if (a24Core.isEmpty(sQuery.trim())) {
                    sQuery = null;
                }
                this.set("_sFilterValue", sQuery);
            }
        }),
        _setFormInputsForEmptyReset: Ember.observer("_bEmptyEntriesOnly", function () {
            //Reset the values on form input when displayed again
            if (this.get("_bSuggestFilter")) {
                //can add more types here as we add more form inputs
                if (!this.get("_bEmptyEntriesOnly")) {
                    this.setFilterValue(this.get("_sFilterValue"), this.get("_objSelectedFilterOption.sFilterType"), this.get("_objSelectedFilterOption.sFilterFromValue"), this.get("_objSelectedFilterOption.sFilterToValue"), this.get("_objSelectedFilterOption.arrMappingData"), false, this.get("_objSelectedFilterOption.bFilterAllowTime"));
                }
            }
        }),
        _setDisplayAddButton: Ember.observer("_sFilterValue", "_bEmptyEntriesOnly", "_sFromDate", "_sToDate", function () {
            //Check if any of the secondaries want to know when the primary value changes
            if (!this.get("_bFromTagClick")) {
                var arrSecondaries = this.get("objHeaderSecondaries.arrFilterSecondary_" + this.get("_objSelectedFilterOption.sProperty"));
                if (!a24Core.isEmpty(arrSecondaries)) {
                    for (var j = 0; j < arrSecondaries.length; j++) {
                        var bDoValidate = false;
                        if (!a24Core.isEmpty(arrSecondaries[j].funcFilterPrimaryChange)) {
                            arrSecondaries[j].funcFilterPrimaryChange(this.get("_sFilterValue"), this.get("_objSelectedFilterOption"), arrSecondaries[j]);

                            //Some of the secondary options or values might have changed so we need to re-setup the item
                            this._funcSetupOrResetSecondaryFilter(arrSecondaries[j]);
                            bDoValidate = true;
                        }

                        //Some of the secondaries could have changed validation so we need to run validation again to check if
                        //all is still valid.
                        if (bDoValidate) {
                            this._funcSecondariesObserver();
                        }
                    }
                }
            }

            //We only display the add filter button if the empty entries only checkbox is selected or if we have
            //a value for the filter. In the date filter case we check that either to or from has a value
            if (this.get("_bDateFilter")) {
                if (this.get("_bEmptyEntriesOnly") || !a24Core.isEmpty(this.get("_sFromDate")) || !a24Core.isEmpty(this.get("_sToDate")) || this.get("_sFilterValue") === this.get("_objEnumEmberLibService.dateFilterOptionsNotEmpty.DATE_FILTER_NOT_EMPTY.backend")) {
                    var objFromDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    objFromDate.setDateFromBrowser(this.get("_sFromDate"), this.get("_objLibConstants.DATE_FORMAT"));

                    var objToDate = a24DateManager.createDate(Ember.get(this, "_objUserSession.objContextDetails.sTimezone"));
                    objToDate.setDateFromBrowser(this.get("_sToDate"), this.get("_objLibConstants.DATE_FORMAT"));
                    if (!a24Core.isEmpty(this.get("_sFromDate")) && !a24Core.isEmpty(this.get("_sToDate")) && objToDate.isBefore(objFromDate)) {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getTokenString("validationDateBefore", "To Date", "From Date"));
                        this.set("_bDisplayAddButton", false);
                        return;
                    }
                    this.set("_bDisplayAddButton", true);
                    return;
                }
            } else if (this.get("_bDropDownMultiFilter")) {
                var arrItems = this.get("_arrFilterValue");
                var bShowButton = arrItems.length > 0;
                for (var i = 0; i < arrItems.length; i++) {
                    if (a24Core.isEmpty(arrItems[i])) {
                        bShowButton = false;
                        break;
                    }
                }
                if (bShowButton || this.get("_bEmptyEntriesOnly")) {
                    this.set("_bDisplayAddButton", true);
                    return;
                }
            } else {
                if (this.get("_bEmptyEntriesOnly") || !a24Core.isEmpty(this.get("_sFilterValue"))) {
                    this.set("_bDisplayAddButton", true);
                    return;
                }
            }
            this.set("_bDisplayAddButton", false);
        }),
        _setFilterInput: Ember.observer("_objSelectedFilterOption", function () {
            //Rest the filter value when the selected filter option changes.
            this.resetFilterBools();
            this._setFilterInputChoices();
        }),
        _setFilterInputChoices: function () {
            var objSelectedFilterOption = this.get("_objSelectedFilterOption");

            //If null if means that the filter is closed then don't set any filter things
            if (a24Core.isEmpty(objSelectedFilterOption)) {
                return;
            }

            if (!a24Core.isEmpty(objSelectedFilterOption.sReactFilterRoute)) {
                this.set("_bDisplayFilterChoices", false);
                this.set("_bHideEmptyCheck", true);
                this.set("_bShowReactFilterIframe", true);

                var arrSecondaries = this.get("objHeaderSecondaries.arrFilterSecondary_" + objSelectedFilterOption.sProperty);

                let objRouteData = {};
                let objPrimaryFilter = {};
                let arrPropCloneSkip = [];
                if (!a24Core.isEmpty(objSelectedFilterOption.arrReactPropCloneSkip)) {
                    arrPropCloneSkip = Ember.copy(objSelectedFilterOption.arrReactPropCloneSkip);
                }

                // Automatically add the ones we know should be skipped
                arrPropCloneSkip.push("arrReactPropCloneSkip");
                arrPropCloneSkip.push("sFilterCustomComponentName");
                arrPropCloneSkip.push("objFilterCustomService");
                arrPropCloneSkip.push("funcTableCustomVal");
                arrPropCloneSkip.push("funcFilterParseQueryValue");
                arrPropCloneSkip.push("funcFilterParseFilterValue");

                Object.keys(objSelectedFilterOption).forEach(function (sKey) {
                    if (arrPropCloneSkip.indexOf(sKey) === -1) {
                        objPrimaryFilter[sKey] = Ember.copy(objSelectedFilterOption[sKey], true);
                    }
                });

                objRouteData.primary_filter = objPrimaryFilter;
                if (!a24Core.isEmpty(arrSecondaries)) {
                    let objSecondaryFilters = Ember.copy(arrSecondaries, true);
                    objRouteData.secondary_filters = objSecondaryFilters;
                }
                if (!a24Core.isEmpty(objSelectedFilterOption.arrReactFilterExtraDataProps)) {
                    for (var i = 0; i < objSelectedFilterOption.arrReactFilterExtraDataProps.length; i++) {
                        let objExtraData = Ember.copy(this.get("objReactFilterExtraData." + objSelectedFilterOption.arrReactFilterExtraDataProps[i]), true);

                        objRouteData[objSelectedFilterOption.arrReactFilterExtraDataProps[i]] = objExtraData;
                    }
                }

                //The react popup already checks if there was data set when it does app_ready and then posts it, so no
                //need to worry about iframe being ready
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: objSelectedFilterOption.sReactFilterRoute,
                        data: objRouteData
                    }
                });

                return;
            }

            //Set the correct bool flag based on the filter type
            //You might note that we use a sFilterType for the type and not send in bBool, bText etc like on the card
            //datagrid table this is because we could potentially have different filters for the same column type e.g
            //date could be a dropdown filter or a datepicker filter. Having the components share the bool flags would
            //complicate things later.
            var sFilterType = objSelectedFilterOption.sFilterType;
            this.set("_bHideEmptyCheck", objSelectedFilterOption.bHideEmptyCheck);
            //Hide or show the more query options depending if filter supports advance things
            if (objSelectedFilterOption.bFilterAnd || objSelectedFilterOption.bFilterOr || objSelectedFilterOption.bFilterSecondary) {
                //For now we for hide the empty checks for advance type filters because of the complexity this brings to
                //the queries. For instance Empty Entries on policies___type_id would give you all policies where type id
                //is empty, but the way the user read the filter he would expect to get all users that have NO policies at
                //all.
                this.set("_bHideEmptyCheck", true);

                if (!objSelectedFilterOption.bFilterHideMoreQueryTypes) {
                    this.set("_bShowSelectedQueryType", true);
                }

                if (!this.get("_bAndOrQuestionAnd") && !this.get("_bAndOrQuestionOr") && !a24Core.isEmpty(objSelectedFilterOption.mFilterValue) && (objSelectedFilterOption.bFilterAnd || objSelectedFilterOption.bFilterOr)) {
                    this.set("_bShowAddOrQuestion", true);
                }
            } else {
                this.set("_bShowSelectedQueryType", false);
            }

            this.set("_sIsLabel", this.get("_objStringsService").getString("is") + ":");
            this.set("_sIsInLabel", this.get("_objStringsService").getString("isIn") + ":");
            this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("selectValue") + "...");

            if (!objSelectedFilterOption.bFilterHideMoreQueryTypes && (objSelectedFilterOption.bFilterAnd || objSelectedFilterOption.bFilterOr || objSelectedFilterOption.bFilterSecondary)) {
                this.set("_sIsLabel", "");

                //NOTE for now we will NOT suport multi select when we have advance query like
                //bFilterAnd or bFilterOr or bFilterSecondary, but putting this dropdown label to empty aswell for
                //when we do
                this.set("_sIsInLabel", "");
            }

            var arrQueryType = [];
            if (a24Core.isEmpty(objSelectedFilterOption.arrQueryType)) {
                //"Is" is the default value so it is not part of the enums
                this.set("_sQueryTypeOptionPrompt", this.get("_objStringsService").getString("is"));
                arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsType");
            } else {
                this.set("_sQueryTypeOptionPrompt", objSelectedFilterOption.arrQueryType[0].sLabel);
                arrQueryType = objSelectedFilterOption.arrQueryType.slice(1);
            }

            if ("text" === sFilterType) {
                if (a24Core.isEmpty(objSelectedFilterOption.arrQueryType)) {
                    this.set("_sQueryTypeOptionPrompt", this.get("_objStringsService").getString("contains"));
                    //"Contains" is the default value for advanced text filters so it is not part of the enums
                    arrQueryType = this.get("_objEnumEmberLibService").getList("filterOptionsTextType");
                }

                this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("enterValue") + "...");
                this.set("_bTextFilter", true);
            } else if ("bool" === sFilterType) {
                this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                this.set("_bBoolFilter", true);
            } else if ("dropdown" === sFilterType) {
                this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                this.set("_bDropDownFilter", true);
            } else if ("dropdownMulti" === sFilterType) {
                this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                this.set("_bDropDownMultiFilter", true);
                this._setDisplayAddButton();
            } else if ("date" === sFilterType) {
                this.set("_arrFilterBetweenArray", [{
                    sTitle: this.get("_objStringsService").getString("ago"),
                    sValue: "ago"
                }, {
                    sTitle: this.get("_objStringsService").getString("fromToday"),
                    sValue: "fromToday"
                }]);
                if (!a24Core.isEmpty(objSelectedFilterOption.arrMappingData)) {
                    this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                } else {
                    this.set("_arrFilterInputArray", this.get("_arrDateFilterData"));
                }
                this.set("_bDateFilter", true);
                this.set("_bDateTimeFilter", objSelectedFilterOption.bFilterAllowTime);
            } else if ("suggest" === sFilterType) {
                this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("enterValue") + "...");
                this.set("_bSuggestFilter", true);
            } else if ("custom" === sFilterType) {
                this.set("_bCustomFilter", true);
                this.set("_bHideEmptyCheck", true); //custom items need to handle empty entries on their own
                this.set("_bShowSelectedQueryType", false);
            }

            let arrQueryTypeDropdown = [];
            for (let i = 0; i < arrQueryType.length; i++) {
                arrQueryTypeDropdown.push({
                    sTitle: arrQueryType[i].sLabel,
                    sValue: arrQueryType[i].sValue
                });
            }
            this.set("_arrQueryType", arrQueryTypeDropdown);

            var objThis = this;

            //One next to allow time to draw a dropdown if filter has one
            Ember.run.next(() => {
                //Another next to first let mdb finish initing a dropdown if filter has one
                Ember.run.next(() => {
                    //Now we should be all good to focus any input that was rendered for the filter option
                    Ember.run.schedule("afterRender", () => {
                        //Add enter event listener to filter option input and also focus field if it
                        //exists

                        var objOptionsInputItem = this.$("#" + this.get("_sFilterOptionsContainerId") + " input");
                        if (!a24Core.isEmpty(objOptionsInputItem)) {
                            //Add enter key listener to input
                            objOptionsInputItem.on("keydown", function (objEvent) {
                                if (objEvent.keyCode === 13 && objThis.get("_bDisplayAddButton") && a24Core.isEmpty($(objThis.$(".multiple-select-dropdown.active")))) {
                                    //Because this is inside jQuery callback add it in Ember run
                                    Ember.run(() => {
                                        objThis.addFilter(false, false);
                                    });
                                }
                            });
                            if (a24Core.isEmpty(this.get("_iAdvanceFilterEdit"))) {
                                //Focus the input
                                objOptionsInputItem.first().focus();
                            }
                        }
                    });
                });
            });
        },
        init: function () {
            this._super(...arguments);
            let objThis = this;

            this.set("_sFilterButtonText", this.get("_objStringsService").getString("filterResults"));
            this.set("_sFilterHeaderText", this.get("_objStringsService").getString("showAllResultsWhere") + ":");
            this.set("_sFilterOptionPrompt", this.get("_objStringsService").getString("selectFilter") + "...");
            this.set("_sEmptyOnlyText", this.get("_objStringsService").getString("emptyEntries"));
            this.set("_sFilterAddButtonText", this.get("_objStringsService").getString("addFilter"));
            this.set("_sFilterUpdateButtonText", this.get("_objStringsEmberLibService").getString("updateFilter"));
            this.set("_sFilterDateFrom", this.get("_objStringsService").getString("from") + ":");
            this.set("_sFilterDateTo", this.get("_objStringsService").getString("to") + ":");
            this.set("_sIsLabel", this.get("_objStringsService").getString("is") + ":");
            this.set("_sIsInLabel", this.get("_objStringsService").getString("isIn") + ":");

            this.set("_funcSortFilterOptions", arrData => {
                if (typeof arrData === "object") {
                    return objThis.get("_objUtilsService").sortObjectArrayOnText(arrData, "sTitle", true);
                } else {
                    return arrData;
                }
            });

            let arrDateFilterDataEnum = this.get("_objEnumEmberLibService").getList("dateFilterOptions");
            let arrDateFilterData = [];
            for (let i = 0; i < arrDateFilterDataEnum.length; i++) {
                arrDateFilterData.push({
                    sTitle: arrDateFilterDataEnum[i].sLabel,
                    sValue: arrDateFilterDataEnum[i].sValue
                });
            }
            this.set("_arrDateFilterData", arrDateFilterData);

            this.set("_sFilterOptionsButtonId", Ember.guidFor({}));
            this.set("_sFilterOptionsDropdownId", Ember.guidFor({}));
            this.set("_sFilterOptionsContainerId", Ember.guidFor({}));
            this.set("_sFilterOptionsTextId", Ember.guidFor({}));
            this.set("_sFilterOptionsPopupId", Ember.guidFor({}));

            this.set("_arrCustomObservers", []);

            if (a24Core.isEmpty(this.get("_arrFilterValue"))) {
                this.set("_arrFilterValue", []);
            }
            if (a24Core.isEmpty(this.get("_arrFilterInputArray"))) {
                this.set("_arrFilterInputArray", []);
            }

            //Set _objQueryParamObject to empty object if empty.
            if (a24Core.isEmpty(this.get("_objQueryParamObject"))) {
                this.set("_objQueryParamObject", {});
            }

            //Set up the initial _objQueryParamObject
            var arrHeaderConfig = this.get("arrHeaderConfig");
            if (!a24Core.isEmpty(arrHeaderConfig)) {
                for (var i = 0; i < arrHeaderConfig.length; i++) {
                    this.setupOrResetSecondaryFilters(arrHeaderConfig[i]);

                    if (!a24Core.isEmpty(arrHeaderConfig[i].sFilterValue)) {
                        this.setHeaderQueryParamObject(arrHeaderConfig[i]);
                    } else if (!a24Core.isEmpty(arrHeaderConfig[i].mFilterValue)) {
                        this.get("_objFilterService").fixupAdvanceQueryValues(arrHeaderConfig[i], this.get("objHeaderSecondaries"));
                        this.setHeaderQueryParamObject(arrHeaderConfig[i]);
                    }
                }
            }

            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.addObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));

            if (!a24Core.isEmpty(this.get("cardDatagridFilterInitEvent"))) {
                this.get("cardDatagridFilterInitEvent")(Ember.copy(this.get("_objQueryParamObject"), true));
            }

            Ember.run.schedule("afterRender", () => {
                var funcKeyDown = function (objEvent) {
                    Ember.run(() => {
                        if (objEvent.keyCode === 70 && !objThis.get("_bDisplayFilterChoices") && !objThis.get("bDisableKeyboardShortcuts") && !Ember.$("body").hasClass("modal-open") && objEvent.target.nodeName !== "INPUT" && objEvent.target.nodeName !== "TEXTAREA") {
                            objThis.filterOptionButtonClick(true);
                        }
                    });
                };

                this.set("_funcKeyDown", funcKeyDown);

                Ember.$(window).on("keydown", this.get("_funcKeyDown"));
            });
        },
        willDestroyElement: function () {
            //Remove all custom added observers
            var arrObservers = this.get("_arrCustomObservers");
            for (var i = 0; i < arrObservers.length; i++) {
                Ember.removeObserver(arrObservers[i].objSubject, arrObservers[i].sProperty, arrObservers[i].objContext, arrObservers[i].funcCallback);
            }

            Ember.$(window).off("keydown", this.get("_funcKeyDown"));

            this._super(...arguments);
        },
        onInputValueChanged: function (arrChildRef) {
            //Only update the value if the not empty entries else the _objFilterValue will go to null when empty entries
            //hides it, we want the value to presist so that unchecking empty entries will show previous value.
            if (!this.get("_bEmptyEntriesOnly")) {
                var sLookup = arrChildRef.join(".");
                if (sLookup === "objChildren.sSuggestInput") {
                    this.set("_objFilterValue", this.get(sLookup + ".mValue"));
                } else if (sLookup === "objChildren.sDateTimeFrom") {
                    this.set("_sFromTime", this.get(sLookup + ".mValue"));
                } else if (sLookup === "objChildren.sDateTimeTo") {
                    this.set("_sToTime", this.get(sLookup + ".mValue"));
                }
            }
        },
        setupOrResetSecondaryFilters: function (objHeader) {
            if (Ember.get(objHeader, "bFilterSecondary") !== true) {
                return;
            }

            Ember.addObserver(this, "_objSelectedQueryType", this, this._funcSecondariesObserver);

            this.get("_arrCustomObservers").pushObject({
                objSubject: this,
                sProperty: "_objSelectedQueryType",
                objContext: this,
                funcCallback: this._funcSecondariesObserver
            });

            var arrSecondaries = this.get("objHeaderSecondaries.arrFilterSecondary_" + objHeader.sProperty);
            for (var i = 0; i < arrSecondaries.length; i++) {
                this._funcSetupOrResetSecondaryFilter(arrSecondaries[i]);
            }
            this._funcSecondariesObserver();
        },
        _funcSetupOrResetSecondaryFilter: function (objSecondary) {

            Ember.set(objSecondary, "_bEmptyEntriesOnly", false);

            if (objSecondary.bFilterRequired) {
                Ember.set(objSecondary, "_sFilterPrompt", this.get("_objStringsService").getString("selectValue") + "...");
            } else {
                Ember.set(objSecondary, "_sFilterPrompt", this.get("_objStringsService").getString("any"));
            }
            if (Ember.get(objSecondary, "sFilterType") === "int") {
                Ember.set(objSecondary, "_bIntFilter", true);
                Ember.set(objSecondary, "_iFilterValue", null);
                Ember.set(objSecondary, "_objFilterValue", null);
                if (a24Core.isEmpty(Ember.get(objSecondary, "arrMappingData"))) {
                    //"Any" is the default value so it is not part of the enums
                    let arrIntOptions = this.get("_objEnumEmberLibService").getList("intFilterOptions");
                    let arrIntOptionsDropdown = [];
                    for (let l = 0; l < arrIntOptions.length; l++) {
                        arrIntOptionsDropdown.push({
                            sTitle: arrIntOptions[l].sLabel,
                            sValue: arrIntOptions[l].sValue
                        });
                    }

                    Ember.set(objSecondary, "arrMappingData", arrIntOptionsDropdown);
                }

                if (!Ember.get(objSecondary, "_bObserverObjFilterValueSet")) {
                    Ember.addObserver(objSecondary, "_iFilterValue", this, this._funcSecondariesObserver);
                    Ember.addObserver(objSecondary, "_objFilterValue", this, this._funcSecondariesObserver);
                    Ember.addObserver(objSecondary, "_bEmptyEntriesOnly", this, this._funcSecondariesObserver);

                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_objFilterValue",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_iFilterValue",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_bEmptyEntriesOnly",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });

                    Ember.set(objSecondary, "_bObserverObjFilterValueSet", true);
                }
            } else if (Ember.get(objSecondary, "sFilterType") === "text") {
                Ember.set(objSecondary, "_bTextFilter", true);
                Ember.set(objSecondary, "_sFilterValue", null);
                if (!Ember.get(objSecondary, "_bObserverObjFilterValueSet")) {
                    Ember.addObserver(objSecondary, "_sFilterValue", this, this._funcSecondariesObserver);
                    Ember.addObserver(objSecondary, "_bEmptyEntriesOnly", this, this._funcSecondariesObserver);

                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_sFilterValue",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_bEmptyEntriesOnly",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });

                    Ember.set(objSecondary, "_bObserverObjFilterValueSet", true);
                }
            } else if (Ember.get(objSecondary, "sFilterType") === "bool") {
                Ember.set(objSecondary, "_bBoolFilter", true);
                Ember.set(objSecondary, "_objFilterValue", null);
                if (!Ember.get(objSecondary, "_bObserverObjFilterValueSet")) {
                    Ember.addObserver(objSecondary, "_objFilterValue", this, this._funcSecondariesObserver);
                    Ember.addObserver(objSecondary, "_bEmptyEntriesOnly", this, this._funcSecondariesObserver);

                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_objFilterValue",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_bEmptyEntriesOnly",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });

                    Ember.set(objSecondary, "_bObserverObjFilterValueSet", true);
                }
            } else if (Ember.get(objSecondary, "sFilterType") === "dropdown") {
                Ember.set(objSecondary, "_bDropDownFilter", true);
                Ember.set(objSecondary, "_objFilterValue", null);
                if (!Ember.get(objSecondary, "_bObserverObjFilterValueSet")) {
                    Ember.addObserver(objSecondary, "_objFilterValue", this, this._funcSecondariesObserver);
                    Ember.addObserver(objSecondary, "_bEmptyEntriesOnly", this, this._funcSecondariesObserver);

                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_objFilterValue",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_bEmptyEntriesOnly",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });

                    Ember.set(objSecondary, "_bObserverObjFilterValueSet", true);
                }
            } else if (Ember.get(objSecondary, "sFilterType") === "dropdownMulti") {
                Ember.set(objSecondary, "_bDropDownMultiFilter", true);
                Ember.set(objSecondary, "_arrFilterValue", []);
                if (!Ember.get(objSecondary, "_bObserverObjFilterValueSet")) {
                    Ember.addObserver(objSecondary, "_arrFilterValue", this, this._funcSecondariesObserver);
                    Ember.addObserver(objSecondary, "_bEmptyEntriesOnly", this, this._funcSecondariesObserver);

                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_arrFilterValue",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_bEmptyEntriesOnly",
                        objContext: this,
                        funcCallback: this._funcSecondariesObserver
                    });

                    Ember.set(objSecondary, "_bObserverObjFilterValueSet", true);
                }
            } else if (Ember.get(objSecondary, "sFilterType") === "date") {
                Ember.set(objSecondary, "_bDateFilter", true);
                Ember.set(objSecondary, "_objFilterValue", null);
                Ember.set(objSecondary, "_bInternalValueChange", true); //this caters for when there is already an observer
                Ember.set(objSecondary, "_sFromDate", null);
                Ember.set(objSecondary, "_sToDate", null);
                Ember.set(objSecondary, "_bInternalValueChange", false);
                Ember.set(objSecondary, "_iFilterValue", null);
                Ember.set(objSecondary, "_iFilterValueTo", null);
                Ember.set(objSecondary, "_objBetweenOption", null);

                if (a24Core.isEmpty(Ember.get(objSecondary, "arrMappingData"))) {
                    let arrDateFilterDataEnum = this.get("_objEnumEmberLibService").getList("dateFilterOptions");
                    let arrDateFilterData = [];
                    for (let i = 0; i < arrDateFilterDataEnum.length; i++) {
                        arrDateFilterData.push({
                            sTitle: arrDateFilterDataEnum[i].sLabel,
                            sValue: arrDateFilterDataEnum[i].sValue
                        });
                    }
                    Ember.set(objSecondary, "arrMappingData", arrDateFilterData);
                }

                Ember.set(objSecondary, "arrMappingData", Ember.copy(Ember.get(objSecondary, "arrMappingData"), true));

                if (!Ember.get(objSecondary, "_bObserverObjFilterValueSet")) {
                    var objSecondaryFilter = objSecondary;
                    var funcFilterDateValuesObserve = () => {

                        var sDateOption = Ember.get(objSecondaryFilter, "_objFilterValue.sValue");
                        if (!a24Core.isEmpty(sDateOption)) {
                            if (this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend") === sDateOption || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                                let objToAndFromDate = this.get("_objFilterService").getDateFromAndToValues(sDateOption, Ember.get(objSecondaryFilter, "_iFilterValue"));

                                Ember.set(objSecondaryFilter, "_bInternalValueChange", true);
                                Ember.set(objSecondaryFilter, "_sFromDate", objToAndFromDate.sFromDate);
                                Ember.set(objSecondaryFilter, "_sToDate", objToAndFromDate.sToDate);
                                Ember.set(objSecondaryFilter, "_bInternalValueChange", false);
                            } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                                let objToAndFromDate = this.get("_objFilterService").getDateFromAndToValues(sDateOption, Ember.get(objSecondaryFilter, "_iFilterValue"), Ember.get(objSecondaryFilter, "_iFilterValueTo"));

                                Ember.set(objSecondaryFilter, "_bInternalValueChange", true);
                                Ember.set(objSecondaryFilter, "_sFromDate", objToAndFromDate.sFromDate);
                                Ember.set(objSecondaryFilter, "_sToDate", objToAndFromDate.sToDate);
                                Ember.set(objSecondaryFilter, "_bInternalValueChange", false);
                            } else if (!a24Core.isEmpty(sDateOption) && this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") !== sDateOption && this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend") !== sDateOption) {
                                Ember.set(objSecondaryFilter, "_objFilterValue", Ember.get(objSecondaryFilter, "arrMappingData." + (Ember.get(objSecondaryFilter, "arrMappingData").length - 1)));
                            }
                        }
                    };
                    var funcFilterDateValuesObserveOnce = () => {
                        if (!Ember.get(objSecondaryFilter, "_bInternalValueChange")) {
                            Ember.run.once(objSecondaryFilter, funcFilterDateValuesObserve);
                            this._funcSecondariesObserver();
                        }
                    };
                    var funcFilterDateObserve = () => {
                        var sDateOption = Ember.get(objSecondaryFilter, "_objFilterValue.sValue");
                        var iFilterValue = Ember.get(objSecondaryFilter, "_iFilterValue");
                        var iFilterValueTo = Ember.get(objSecondaryFilter, "_iFilterValueTo");
                        let objToAndFromDate = this.get("_objFilterService").getDateFromAndToValues(sDateOption, iFilterValue, iFilterValueTo);
                        if (objToAndFromDate.bCustomRange) {
                            //We do not want to change the dates since the user could be altering a previous choice
                            return;
                        } else {
                            //Need to remove observers while setting the dates since test cases do not adhere to the
                            //`once` and then the funcFilterDateValuesObserveOnce will run twice in test
                            Ember.set(objSecondaryFilter, "_bInternalValueChange", true);
                            Ember.set(objSecondaryFilter, "_sFromDate", objToAndFromDate.sFromDate);
                            Ember.set(objSecondaryFilter, "_sToDate", objToAndFromDate.sToDate);
                            Ember.set(objSecondaryFilter, "_bInternalValueChange", false);
                        }
                    };
                    var funcFilterDateObserveOnce = () => {
                        Ember.run.once(objSecondaryFilter, funcFilterDateObserve);
                        this._funcSecondariesObserver();
                    };

                    Ember.addObserver(objSecondary, "_objFilterValue", objSecondary, funcFilterDateObserveOnce);
                    Ember.addObserver(objSecondary, "_sFromDate", objSecondary, funcFilterDateValuesObserveOnce);
                    Ember.addObserver(objSecondary, "_sToDate", objSecondary, funcFilterDateValuesObserveOnce);
                    Ember.addObserver(objSecondary, "_bEmptyEntriesOnly", objSecondary, funcFilterDateValuesObserveOnce);
                    Ember.addObserver(objSecondary, "_iFilterValue", objSecondary, funcFilterDateValuesObserveOnce);
                    Ember.addObserver(objSecondary, "_iFilterValueTo", objSecondary, funcFilterDateValuesObserveOnce);
                    Ember.addObserver(objSecondary, "_objBetweenOption", objSecondary, funcFilterDateValuesObserveOnce);

                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_objFilterValue",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateObserveOnce
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_sFromDate",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateValuesObserveOnce
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_sToDate",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateValuesObserveOnce
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_bEmptyEntriesOnly",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateValuesObserveOnce
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_iFilterValue",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateValuesObserveOnce
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_iFilterValueTo",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateValuesObserveOnce
                    });
                    this.get("_arrCustomObservers").pushObject({
                        objSubject: objSecondary,
                        sProperty: "_objBetweenOption",
                        objContext: objSecondary,
                        funcCallback: funcFilterDateValuesObserveOnce
                    });

                    Ember.set(objSecondary, "_bObserverObjFilterValueSet", true);
                }
            } else if (Ember.get(objSecondary, "sFilterType") === "custom") {
                Ember.set(objSecondary, "_bCustomFilter", true);
            }
        },
        _funcSecondariesObserver: function () {
            Ember.run.once(this, this._funcValidateSecondaries);
        },
        _funcValidateSecondaries: function () {
            if (a24Core.isEmpty(this.get("_objSelectedFilterOption")) || !this.get("_objSelectedFilterOption.bFilterSecondary")) {
                return;
            }

            this.set("_bSecondariesValid", true);

            if (this.get("_objSelectedQueryType.sValue") === this.get("_objEnumEmberLibService.filterOptionsType.IS_NOT.backend") || this.get("_objSelectedQueryType.sValue") === this.get("_objLibConstants").CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY) {
                return;
            }

            var arrSecondaries = this.get("objHeaderSecondaries.arrFilterSecondary_" + this.get("_objSelectedFilterOption.sProperty"));

            var mReturn = this.get("_objFilterService").validateSecondaries(arrSecondaries);

            if (!a24Core.isEmpty(mReturn)) {
                this.set("_bSecondariesValid", false);
            }
        },
        setHeaderQueryParamObjects: function () {
            this.removeObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.removeObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));

            var objQueryParamObject = this.get("_objQueryParamObject");
            var arrHeaderConfig = this.get("arrHeaderConfig");

            var bHasFilterApplied = false;
            if (!a24Core.isEmpty(arrHeaderConfig)) {
                for (var i = 0; i < arrHeaderConfig.length; i++) {
                    if (arrHeaderConfig[i].bFilterAnd || arrHeaderConfig[i].bFilterOr || arrHeaderConfig[i].bFilterSecondary || arrHeaderConfig[i].sFilterType === "custom") {
                        if (a24Core.isEmpty(arrHeaderConfig[i].mFilterValue)) {
                            delete objQueryParamObject[arrHeaderConfig[i].sProperty];
                        } else {
                            this.get("_objFilterService").fixupAdvanceQueryValues(arrHeaderConfig[i], this.get("objHeaderSecondaries"));
                            this.setHeaderQueryParamObject(arrHeaderConfig[i]);
                            bHasFilterApplied = true;
                        }
                    } else if (a24Core.isEmpty(arrHeaderConfig[i].sFilterValue)) {
                        delete objQueryParamObject[arrHeaderConfig[i].sProperty];
                    } else {
                        this.setHeaderQueryParamObject(arrHeaderConfig[i]);
                        bHasFilterApplied = true;
                    }
                }
            }
            this.set("bHasFilterApplied", bHasFilterApplied);

            //Let parent know the filters changed
            if (!a24Core.isEmpty(this.get("cardDatagridFilterParamChangeEvent"))) {
                this.get("cardDatagridFilterParamChangeEvent")(Ember.copy(this.get("_objQueryParamObject"), true));
            }

            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.addObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
        },
        setCustomDataChoiceObserver: Ember.observer("_sFromDate", "_sToDate", "_sFromTime", "_sToTime", "_iFilterValue", "_iFilterValueTo", "_objBetweenOption", function () {
            if (this.get("_bCheckForCustomDate")) {
                Ember.run.once(this, this.setCustomDataChoice);
            }
            if (this.get("_bDateTimeFilter")) {
                Ember.run.once(this, this._setDateTimes);
            }
        }),
        _setDateTimes: function () {
            if (!a24Core.isEmpty(this.get("_objFilterValue"))) {
                var sDateOption = this.get("_objFilterValue").sValue;
                if (this.get("_bDateFilter") && !a24Core.isEmpty(sDateOption) && (this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend") === sDateOption)) {
                    Ember.run.next(() => {
                        Ember.run.schedule("afterRender", () => {
                            if (!a24Core.isEmpty(this.get("objChildren.sDateTimeFrom"))) {
                                if (a24Core.isEmpty(this.get("_sFromDate"))) {
                                    this.get("objChildren.sDateTimeFrom").clear();
                                } else if (a24Core.isEmpty(this.get("_sFromTime"))) {
                                    this.get("objChildren.sDateTimeFrom").setValueFromOutside("00:00");
                                }
                                if (a24Core.isEmpty(this.get("_sToDate"))) {
                                    this.get("objChildren.sDateTimeTo").clear();
                                } else if (a24Core.isEmpty(this.get("_sToTime"))) {
                                    this.get("objChildren.sDateTimeTo").setValueFromOutside("23:59");
                                }
                            }
                        });
                    });
                }
            }
        },
        setCustomDataChoice: function () {
            //If empty we know that the filter is closed.
            if (!a24Core.isEmpty(this.get("_objFilterValue"))) {
                var sDateOption = this.get("_objFilterValue").sValue;

                if (this.get("_bDateFilter") && !a24Core.isEmpty(sDateOption) && (this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend") === sDateOption || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0)) {
                    var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    this.set("_bCheckForCustomDate", false);
                    if (a24Core.isEmpty(this.get("_iFilterValue")) || this.get("_iFilterValue") === 0 || sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0 && (a24Core.isEmpty(this.get("_iFilterValueTo")) || this.get("_iFilterValueTo") === 0 || a24Core.isEmpty(this.get("_objBetweenOption")))) {
                        this.set("_sFromDate", null);
                        this.set("_sToDate", null);
                    } else {
                        if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0) {
                            let sUnit = sDateOption.split("_")[1];
                            objCurrentDate.setTimeToStartOfDay();
                            objCurrentDate.subtractTimeFromDate(sUnit, this.get("_iFilterValue"));
                            this.set("_sFromDate", objCurrentDate.getDateFormat());
                        } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0) {
                            let sUnit = sDateOption.split("_")[1];
                            objCurrentDate.setTimeToStartOfDay();
                            objCurrentDate.addTimeToDate(sUnit, this.get("_iFilterValue"));
                            this.set("_sFromDate", objCurrentDate.getDateFormat());
                        } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0) {
                            let sUnit = sDateOption.split("_")[1];
                            objCurrentDate.setTimeToEndOfDay();
                            this.set("_sToDate", objCurrentDate.getDateFormat());
                            objCurrentDate.setTimeToStartOfDay();
                            objCurrentDate.subtractTimeFromDate(sUnit, this.get("_iFilterValue"));
                            this.set("_sFromDate", objCurrentDate.getDateFormat());
                        } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                            let sUnit = sDateOption.split("_")[1];
                            objCurrentDate.setTimeToEndOfDay();
                            objCurrentDate.subtractTimeFromDate(sUnit, this.get("_iFilterValue"));
                            this.set("_sToDate", objCurrentDate.getDateFormat());
                        } else if (this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") === sDateOption || this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") === sDateOption) {
                            this.set("_sToDate", objCurrentDate.getDateFormat());
                            objCurrentDate.subtractTimeFromDate(a24Constants.MOMENT_TIME_DAYS, this.get("_iFilterValue"));
                            this.set("_sFromDate", objCurrentDate.getDateFormat());
                        } else if (sDateOption.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                            let objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            let objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                            if (!a24Core.isEmpty(this.get("_iFilterValue")) && !a24Core.isEmpty(this.get("_iFilterValueTo")) && !a24Core.isEmpty(this.get("_objBetweenOption"))) {
                                let iDays = parseInt(this.get("_iFilterValue"));
                                let iDaysTo = parseInt(this.get("_iFilterValueTo"));

                                let iHigh = iDays >= iDaysTo ? iDays : iDaysTo;
                                let iLow = iDays < iDaysTo ? iDays : iDaysTo;

                                if (this.get("_objBetweenOption.sValue") === "ago") {
                                    objFromDate.setTimeToStartOfDay();
                                    objFromDate.subtractTimeFromDate(a24Constants.MOMENT_TIME_DAYS, iHigh);
                                    objToDate.setTimeToEndOfDay();
                                    objToDate.subtractTimeFromDate(a24Constants.MOMENT_TIME_DAYS, iLow);
                                } else if (this.get("_objBetweenOption.sValue") === "fromToday") {
                                    objFromDate.setTimeToStartOfDay();
                                    objFromDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, iLow);
                                    objToDate.setTimeToEndOfDay();
                                    objToDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, iHigh);
                                }
                                this.set("_sFromDate", objFromDate.getDateFormat());
                                this.set("_sToDate", objToDate.getDateFormat());
                            }
                        } else {
                            this.set("_sFromDate", objCurrentDate.getDateFormat());
                            objCurrentDate.addTimeToDate(a24Constants.MOMENT_TIME_DAYS, this.get("_iFilterValue"));
                            this.set("_sToDate", objCurrentDate.getDateFormat());
                        }
                    }
                    this.set("_bCheckForCustomDate", true);
                } else if (this.get("_bDateFilter") && !a24Core.isEmpty(sDateOption) && this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") !== sDateOption && this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend") !== sDateOption) {
                    this.set("_objFilterValue", this.get("_arrDateFilterData." + (this.get("_arrDateFilterData").length - 1)));
                }
            }
        },
        resetFilterBools: function () {
            //Clear all the variables used and share in the filter options popup
            this.set("_bDisplayAddButton", false);
            this.set("_bCustomFilter", false);
            this.set("_bTextFilter", false);
            this.set("_bBoolFilter", false);
            this.set("_bDropDownFilter", false);
            this.set("_bDropDownMultiFilter", false);
            this.set("_bDateFilter", false);
            this.set("_bDateTimeFilter", false);
            this.set("_bSuggestFilter", false);
            this.set("_sFilterValue", null);
            this.set("_objFilterValue", null);
            this.set("_sFilterInputPlaceHolder", null);
            this.set("_arrFilterInputArray", []);
            this.set("_arrFilterValue", []);
            this.set("_bEmptyEntriesOnly", false);

            this.set("_bCheckForCustomDate", false);
            this.set("_sFromDate", "");
            this.set("_sToDate", "");
            this.set("_sFromTime", "");
            this.set("_sToTime", "");
            this.set("_iFilterValue", null);
            this.set("_iFilterValueTo", null);
            this.set("_objBetweenOption", null);
            this.set("_bCheckForCustomDate", true);

            this.set("_bHideEmptyCheck", false);
            this.set("_objSelectedQueryType", null);
            this.set("_bShowSelectedQueryType", false);
            this.set("_bShowAddOrQuestion", false);
            this.set("_bShowSecondaryFilters", true);
            this.set("_bAndOrQuestionAnd", false);
            this.set("_bAndOrQuestionOr", false);
            this.set("_iAdvanceFilterEdit", null);

            this.set("_mFilterValueReact", null);

            //Reset form input fields
            if (!a24Core.isEmpty(this.get("objChildren.sSuggestInput"))) {
                this.get("objChildren.sSuggestInput").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren.sDateTimeFrom"))) {
                this.get("objChildren.sDateTimeFrom").clear();
            }
            if (!a24Core.isEmpty(this.get("objChildren.sDateTimeTo"))) {
                this.get("objChildren.sDateTimeTo").clear();
            }

            //Reset all the secondaries filters
            var arrHeaderConfig = this.get("arrHeaderConfig");
            if (!a24Core.isEmpty(arrHeaderConfig)) {
                for (var i = 0; i < arrHeaderConfig.length; i++) {
                    if (arrHeaderConfig[i].sFilterType === "custom") {
                        arrHeaderConfig[i].objFilterCustomService.funcFilterCustomPrimaryReset(arrHeaderConfig[i]);
                    }
                    this.setupOrResetSecondaryFilters(arrHeaderConfig[i]);
                }
            }
        },
        addFilter: function (bDoAndNext, bDoOrNext) {
            this.removeObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.removeObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));

            this.set("bShowSaveAction", true);

            var objSelectedFilterOption = this.get("_objSelectedFilterOption");

            var sHeaderFilterValue = null;
            if (this.get("_bEmptyEntriesOnly")) {
                sHeaderFilterValue = "null";
            } else {
                sHeaderFilterValue = this.get("_sFilterValue");
            }
            Ember.set(objSelectedFilterOption, "sFilterValue", sHeaderFilterValue);

            //If date filter set the 2 extra properties
            if ("date" === objSelectedFilterOption.sFilterType) {
                if (sHeaderFilterValue === "null") {
                    Ember.set(objSelectedFilterOption, "sFilterFromValue", null);
                    Ember.set(objSelectedFilterOption, "sFilterToValue", null);
                } else {
                    if (sHeaderFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                        Ember.set(objSelectedFilterOption, "sFilterValue", `${sHeaderFilterValue}_${this.get("_objBetweenOption.sValue")}_${this.get("_iFilterValue")}_${this.get("_iFilterValueTo")}`);
                    }
                    if (this.get("_bDateTimeFilter")) {
                        Ember.set(objSelectedFilterOption, "sFilterFromValue", this.get("_sFromDate") + " " + this.get("_sFromTime"));
                        Ember.set(objSelectedFilterOption, "sFilterToValue", this.get("_sToDate") + " " + this.get("_sToTime"));
                    } else {
                        Ember.set(objSelectedFilterOption, "sFilterFromValue", this.get("_sFromDate"));
                        Ember.set(objSelectedFilterOption, "sFilterToValue", this.get("_sToDate"));
                    }
                }
            }

            if (!a24Core.isEmpty(objSelectedFilterOption.sReactFilterRoute)) {
                Ember.set(objSelectedFilterOption, "mFilterValue", this.get("_mFilterValueReact"));
                Ember.set(objSelectedFilterOption, "sFilterValue", null);
            } else if (objSelectedFilterOption.bFilterSecondary || objSelectedFilterOption.bFilterAnd || objSelectedFilterOption.bFilterOr || objSelectedFilterOption.sFilterType === "custom") {
                this.get("_objFilterService").buildAdvanceFilter(objSelectedFilterOption, this.get("_bAndOrQuestionAnd"), this.get("_bAndOrQuestionOr"), this.get("_objSelectedQueryType"), this.get("objHeaderSecondaries"), this.get("_iAdvanceFilterEdit"));
            }

            this.setHeaderQueryParamObject(objSelectedFilterOption);

            this.resetFilterBools();

            if (bDoAndNext || bDoOrNext) {
                this.set("_bAndOrQuestionAnd", bDoAndNext);
                this.set("_bAndOrQuestionOr", bDoOrNext);
                this._setFilterInputChoices();
            } else {
                this.set("_objSelectedFilterOption", null);
                this.set("_bDisplayFilterChoices", false);
                if (!a24Core.isEmpty(this.get("cardDatagridFilterDropdownCloseEvent"))) {
                    this.get("cardDatagridFilterDropdownCloseEvent")();
                }
            }

            //Let parent know the filters changed
            if (!a24Core.isEmpty(this.get("cardDatagridFilterChangeEvent"))) {
                this.get("cardDatagridFilterChangeEvent")(objSelectedFilterOption.sProperty, Ember.copy(this.get("_objQueryParamObject"), true));
            }

            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.addObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
        },
        _funcHeaderFilter: function (objHeaderItem) {
            // Used to disable filters on the dropdown
            return !objHeaderItem.bStopFiltering;
        },
        setHeaderQueryParamObject: function (objHeaderItem) {
            var objQueryAndTag = this.get("_objFilterService").createQueryAndTag(objHeaderItem, true, this.get("objHeaderSecondaries"));

            if (!a24Core.isEmpty(objHeaderItem.funcFilterParseQueryValue)) {
                objQueryAndTag.objQuery = objHeaderItem.funcFilterParseQueryValue(objQueryAndTag.objQuery);
            }

            this.set("_objQueryParamObject." + objHeaderItem.sProperty, objQueryAndTag.objQuery);

            if (!a24Core.isEmpty(objQueryAndTag.arrTag)) {
                Ember.set(objHeaderItem, "arrTagValue", objQueryAndTag.arrTag);
            } else {
                Ember.set(objHeaderItem, "arrTagValue", null);
            }

            if (!a24Core.isEmpty(objQueryAndTag.sTagSimple)) {
                Ember.set(objHeaderItem, "sTagValueSimple", objQueryAndTag.sTagSimple);
            } else {
                Ember.set(objHeaderItem, "sTagValueSimple", null);
            }

            if (!a24Core.isEmpty(objQueryAndTag.sTag)) {
                Ember.set(objHeaderItem, "sTagValue", objQueryAndTag.sTag);
            } else {
                Ember.set(objHeaderItem, "sTagValue", null);
            }
        },
        removeFilter: function (sProperty, iIndex, bEntireFilter) {
            this.removeObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.removeObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));

            this.set("bShowSaveAction", true);

            var arrHeaderConfig = this.get("arrHeaderConfig");
            var objQueryParamObject = this.get("_objQueryParamObject");

            //Loop over the header, find the one to remove and reset the filter and tag value.
            for (var i = 0; i < arrHeaderConfig.length; i++) {
                if (arrHeaderConfig[i].sProperty === sProperty) {

                    //NOTE When the `x` is clicked on individual item advance filter items we remove the item unless
                    //it is the last item then we remove it like normal.
                    if (arrHeaderConfig[i].bFilterSecondary || arrHeaderConfig[i].bFilterAnd || arrHeaderConfig[i].bFilterOr || arrHeaderConfig[i].sFilterType === "custom") {
                        //If we have advance item then when iIndex is empty we know that we are dealing with a single item,
                        //thus we can set iIndex to 0
                        if (a24Core.isEmpty(iIndex)) {
                            iIndex = 0;
                        }
                        if (bEntireFilter) {
                            this.set("arrHeaderConfig." + i + ".mFilterValue", []);
                        } else {
                            this.get("arrHeaderConfig." + i + ".mFilterValue").removeAt(iIndex);
                        }

                        if (this.get("arrHeaderConfig." + i + ".mFilterValue").length !== 0) {
                            this.setHeaderQueryParamObject(this.get("arrHeaderConfig." + i));
                            break;
                        }
                        this.set("arrHeaderConfig." + i + ".arrTagValue", null);
                    }

                    this.set("arrHeaderConfig." + i + ".sFilterValue", null);
                    this.set("arrHeaderConfig." + i + ".sTagValueSimple", null);
                    this.set("arrHeaderConfig." + i + ".sTagValue", null);

                    if ("date" === arrHeaderConfig[i].sFilterType) {
                        this.set("arrHeaderConfig." + i + ".sFilterFromValue", null);
                        this.set("arrHeaderConfig." + i + ".sFilterToValue", null);
                    }

                    if (arrHeaderConfig[i].bFilterSecondary || arrHeaderConfig[i].bFilterAnd || arrHeaderConfig[i].bFilterOr || arrHeaderConfig[i].sFilterType === "custom") {
                        this.set("arrHeaderConfig." + i + ".mFilterValue", null);
                    }

                    delete objQueryParamObject[sProperty];
                    break;
                }
            }

            //Let parent know the filters changed
            if (!a24Core.isEmpty(this.get("cardDatagridFilterChangeEvent"))) {
                this.get("cardDatagridFilterChangeEvent")(sProperty, Ember.copy(objQueryParamObject, true));
            }

            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            this.addObserver("arrHeaderConfig.@each.mFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
        },
        filterOptionButtonClick: function (bAutoFocus) {
            this.resetFilterBools();
            this.set("_objSelectedFilterOption", null);
            this.set("_bDisplayFilterChoices", true);

            var objCurrentController = this;

            //Here we do a next since we first need the DOM to draw the filter options popup before we can access
            //the first dropdown to focus it(seems it can only be focused in a ember next, might have to do with how
            //mdb inits the dropdown) and also override the click on the popup so that it does not close the
            //popup when click happens on itself.
            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    var objOptionsDropdown = objCurrentController.$("#" + objCurrentController.get("_sFilterOptionsDropdownId") + " input");
                    if (objCurrentController.get("_bDisplayFilterChoices") && !a24Core.isEmpty(objOptionsDropdown) && bAutoFocus) {
                        objOptionsDropdown.first().focus();
                    }
                });
            });

            if (!a24Core.isEmpty(this.get("cardDatagridFilterDropdownOpenEvent"))) {
                this.get("cardDatagridFilterDropdownOpenEvent")();
            }
        },
        setFilterValue: function (sFilterValue, sFilterType, sFilterFromValue, sFilterToValue, arrMappingData, bAdvance, bAllowTime) {
            this.set("_iFilterValue", null);
            this.set("_iFilterValueTo", null);
            this.set("_objBetweenOption", null);
            if ("null" === sFilterValue) {
                this.set("_sFilterValue", "");
                this.set("_bEmptyEntriesOnly", true);
            } else {
                this.set("_sFilterValue", sFilterValue);
            }
            if ("date" === sFilterType) {
                let sFilterFromTime = "00:00";
                let sFilterToTime = "23:59";

                var objFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                var objToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));

                if (!a24Core.isEmpty(sFilterValue) && (bAdvance && sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_CUSTOM_RANGE.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_CUSTOM.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_AFTER.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend") || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0)) {
                    if (bAdvance) {
                        if (!a24Core.isEmpty(sFilterFromValue)) {
                            objFromDate.setDateFromRest(sFilterFromValue);
                            sFilterFromValue = objFromDate.getDateFormat();
                        }
                        if (!a24Core.isEmpty(sFilterToValue)) {
                            objToDate.setDateFromRest(sFilterToValue);
                            sFilterToValue = objToDate.getDateFormat();
                        }
                    } else {
                        if (bAllowTime) {
                            objFromDate.setDateFromBrowser(sFilterFromValue, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                            objToDate.setDateFromBrowser(sFilterToValue, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                        } else {
                            objFromDate.setDateFromBrowser(sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                            objToDate.setDateFromBrowser(sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));
                        }
                    }

                    if (sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_NOT_EXPIRED_GRACE.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsExpiry.DATE_FILTER_EXPIRY_WILL_NOT_EXPIRE.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_WITHIN_LAST.backend"), 0) === 0 || sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsObtained.DATE_FILTER_OBTAINED_AT_LEAST.backend"), 0) === 0) {
                        let sUnit = sFilterValue.split("_")[1];
                        objFromDate.setTimeToStartOfDay();
                        objToDate.setTimeToStartOfDay();
                        this.set("_iFilterValue", Math.round(objToDate.difference(objFromDate, sUnit, true)));
                    } else if (sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptionsFutureDates.DATE_FILTER_FUTURE_DATES_WITHIN_THE_NEXT.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_LAST.backend") || sFilterValue === this.get("_objEnumEmberLibService.dateFilterOptions.DATE_FILTER_NOT_WITHIN_THE_NEXT.backend")) {
                        objFromDate.setTimeToStartOfDay();
                        objToDate.setTimeToStartOfDay();
                        this.set("_iFilterValue", Math.round(objToDate.difference(objFromDate, a24Constants.MOMENT_TIME_DAYS, true)));
                    } else if (sFilterValue.lastIndexOf(this.get("_objEnumEmberLibService.dateFilterOptionsBetween.DATE_FILTER_BETWEEN.backend"), 0) === 0) {
                        let arrExtraOptions = sFilterValue.split("_");
                        this.set("_iFilterValue", arrExtraOptions[2]);
                        this.set("_iFilterValueTo", arrExtraOptions[3]);
                        var arrBetweenArray = this.get("_arrFilterBetweenArray");
                        for (var h = 0; h < arrBetweenArray.length; h++) {
                            if (arrBetweenArray[h].sValue === arrExtraOptions[1]) {
                                this.set("_objBetweenOption", arrBetweenArray[h]);
                                break;
                            }
                        }
                    }
                } else {
                    if (bAllowTime) {
                        objFromDate.setDateFromBrowser(sFilterFromValue, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                        objToDate.setDateFromBrowser(sFilterToValue, this.get("_objLibConstants.DATE_TIME_FORMAT"));
                    } else {
                        objFromDate.setDateFromBrowser(sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objToDate.setDateFromBrowser(sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));
                    }
                }

                if (!a24Core.isEmpty(sFilterFromValue)) {
                    sFilterFromTime = objFromDate.getTimeFormat();
                }
                if (!a24Core.isEmpty(sFilterToValue)) {
                    sFilterToTime = objToDate.getTimeFormat();
                }

                this.set("_bCheckForCustomDate", false);
                if (!a24Core.isEmpty(sFilterFromValue)) {
                    this.set("_sFromDate", sFilterFromValue);
                }
                if (!a24Core.isEmpty(sFilterToValue)) {
                    this.set("_sToDate", sFilterToValue);
                }
                if (bAllowTime) {
                    if (!a24Core.isEmpty(sFilterFromValue)) {
                        this.set("_sFromTime", sFilterFromTime);
                    }
                    if (!a24Core.isEmpty(sFilterToValue)) {
                        this.set("_sToTime", sFilterToTime);
                    }
                }
                this.set("_bCheckForCustomDate", true);
                var arrDateMappings = this.get("_arrDateFilterData");
                var objSelectedFilterOption = this.get("_objSelectedFilterOption");
                if (!a24Core.isEmpty(objSelectedFilterOption.arrMappingData)) {
                    arrDateMappings = objSelectedFilterOption.arrMappingData;
                }
                var objDateFilterValue = null;
                for (var l = 0; l < arrDateMappings.length; l++) {
                    if (sFilterValue.includes(arrDateMappings[l].sValue)) {
                        objDateFilterValue = arrDateMappings[l];
                        break;
                    } else if (arrDateMappings[l].sValue === sFilterValue) {
                        objDateFilterValue = arrDateMappings[l];
                        break;
                    }
                }

                this.set("_objFilterValue", objDateFilterValue);

                if (bAllowTime) {
                    //set the value on the input in next since it needs to render first
                    Ember.run.next(() => {
                        Ember.run.schedule("afterRender", () => {
                            if (!a24Core.isEmpty(this.get("objChildren.sDateTimeFrom"))) {
                                this.get("objChildren.sDateTimeFrom").setValueFromOutside(sFilterFromTime);
                            }
                            if (!a24Core.isEmpty(this.get("objChildren.sDateTimeTo"))) {
                                this.get("objChildren.sDateTimeTo").setValueFromOutside(sFilterToTime);
                            }
                        });
                    });
                }
            } else if ("bool" === sFilterType || "dropdown" === sFilterType) {
                var arrMappings = arrMappingData;
                var objFilterValue = null;
                for (var k = 0; k < arrMappings.length; k++) {
                    if (arrMappings[k].sValue === sFilterValue) {
                        objFilterValue = arrMappings[k];
                        break;
                    }
                }
                this.set("_objFilterValue", objFilterValue);
            } else if ("dropdownMulti" === sFilterType) {
                var arrSelectedItems = [];
                if (!a24Core.isEmpty(sFilterValue)) {
                    var arrItems = sFilterValue.split("|");
                    var arrData = arrMappingData;
                    for (var i = 0; i < arrItems.length; i++) {
                        for (var j = 0; j < arrData.length; j++) {
                            if (arrData[j].sValue === arrItems[i]) {
                                arrSelectedItems.push(arrData[j]);
                                break;
                            }
                        }
                    }
                }
                this.set("_arrFilterValue", arrSelectedItems);
            } else if ("suggest" === sFilterType) {
                var objSelected = null;
                if (!a24Core.isEmpty(sFilterValue) && sFilterValue !== "null") {
                    var arrSuggestItems = sFilterValue.split("||"); //double pipe for safety.
                    objSelected = {};
                    objSelected[this.get("_objSelectedFilterOption.sFilterOptionsValueKey")] = arrSuggestItems[0];
                    objSelected[this.get("_objSelectedFilterOption.sFilterOptionsLabelKey")] = arrSuggestItems[1];
                }
                this.set("_objFilterValue", objSelected);

                //set the value on the input in next since it needs to render first
                Ember.run.next(() => {
                    Ember.run.schedule("afterRender", () => {
                        this.get("objChildren.sSuggestInput").setValueFromOutside(objSelected);
                    });
                });
            }
        },
        advanceFilterTagClicked: function (objFilterItem, iIndex) {
            var arrObservers = this.get("_arrCustomObservers");

            arrObservers.forEach(objObserver => {
                Ember.removeObserver(objObserver.objSubject, objObserver.sProperty, objObserver.objContext, objObserver.funcCallback);
            });

            if (a24Core.isEmpty(iIndex)) {
                iIndex = 0;
            }

            this.set("_iAdvanceFilterEdit", iIndex);
            this.set("_bShowAddOrQuestion", false);

            // This is so that the AND and OR question is faked to be answered when edit is clicked
            this.set("_bAndOrQuestionAnd", objFilterItem.mFilterValue[iIndex].bA);
            this.set("_bAndOrQuestionOr", objFilterItem.mFilterValue[iIndex].bO);

            if (objFilterItem.sFilterType === "custom") {
                objFilterItem.objFilterCustomService.funcFilterCustomSetEditState(objFilterItem, iIndex);
            } else {
                var objFilterValue = objFilterItem.mFilterValue[iIndex];

                this._setAdvanceFilterValue(objFilterItem, objFilterValue);

                Ember.run.schedule("afterRender", () => {
                    //We set the secondaries first so that they have values when the inputs init AFTER the primary has a value.
                    if (objFilterItem.bFilterSecondary) {
                        var arrSecondaries = this.get("objHeaderSecondaries.arrFilterSecondary_" + objFilterItem.sProperty);

                        this.get("_objFilterService").setValuesForSecondariesFilter(arrSecondaries, objFilterValue);

                        //We need to set the primary value again if we had secondaries to ensure that all inputs render with
                        //the correct state, value and floating label.
                        this._setAdvanceFilterValue(objFilterItem, objFilterValue);

                        this._funcSecondariesObserver();
                    }

                    if (objFilterValue.mValue === "null") {
                        if (a24Core.isEmpty(this.get("_arrQueryType").findBy("sValue", this.get("_objLibConstants").CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY))) {
                            this.set("_objSelectedQueryType", null);
                        } else {
                            this.set("_objSelectedQueryType", {
                                sValue: this.get("_objLibConstants").CARD_DATAGRID_FILTER_QUERY_TYPE_EMPTY
                            });
                        }
                    } else if (objFilterValue.bStartWith) {
                        if (a24Core.isEmpty(this.get("_arrQueryType").findBy("sValue", this.get("_objEnumEmberLibService.filterOptionsTextType.STARTS_WITH.backend")))) {
                            this.set("_objSelectedQueryType", null);
                        } else {
                            this.set("_objSelectedQueryType", {
                                sValue: this.get("_objEnumEmberLibService.filterOptionsTextType.STARTS_WITH.backend")
                            });
                        }
                    } else if (objFilterValue.bEndsWith) {
                        if (a24Core.isEmpty(this.get("_arrQueryType").findBy("sValue", this.get("_objEnumEmberLibService.filterOptionsTextType.ENDS_WITH.backend")))) {
                            this.set("_objSelectedQueryType", null);
                        } else {
                            this.set("_objSelectedQueryType", {
                                sValue: this.get("_objEnumEmberLibService.filterOptionsTextType.ENDS_WITH.backend")
                            });
                        }
                    } else if (objFilterValue.bLike) {
                        if (a24Core.isEmpty(this.get("_arrQueryType").findBy("sValue", this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS")))) {
                            this.set("_objSelectedQueryType", null);
                        } else {
                            this.set("_objSelectedQueryType", {
                                sValue: this.get("_objLibConstants.CARD_DATAGRID_FILTER_QUERY_TYPE_CONTAINS")
                            });
                        }
                    } else if (objFilterValue.bDelimitedNot) {
                        if (a24Core.isEmpty(this.get("_arrQueryType").findBy("sValue", this.get("_objEnumEmberLibService.filterOptionsTextType.IS_NOT.backend")))) {
                            this.set("_objSelectedQueryType", null);
                        } else {
                            this.set("_objSelectedQueryType", {
                                sValue: this.get("_objEnumEmberLibService.filterOptionsTextType.IS_NOT.backend")
                            });
                        }
                    } else {
                        if (a24Core.isEmpty(this.get("_arrQueryType").findBy("sValue", this.get("_objEnumEmberLibService.filterOptionsTextType.IS.backend")))) {
                            this.set("_objSelectedQueryType", null);
                        } else {
                            this.set("_objSelectedQueryType", {
                                sValue: this.get("_objEnumEmberLibService.filterOptionsTextType.IS.backend")
                            });
                        }
                    }
                });
            }

            Ember.run.next(() => {
                arrObservers.forEach(objObserver => {
                    Ember.addObserver(objObserver.objSubject, objObserver.sProperty, objObserver.objContext, objObserver.funcCallback);
                });
            });
        },
        _setAdvanceFilterValue: function (objFilterItem, objFilterValue) {
            if (objFilterItem.sFilterType === "suggest") {
                this.setFilterValue(objFilterValue.mValue + "||" + objFilterValue.sFilterValue, objFilterItem.sFilterType, objFilterItem.sFilterFromValue, objFilterItem.sFilterToValue, objFilterItem.arrMappingData, false, objFilterItem.bFilterAllowTime);
            } else if (objFilterItem.sFilterType === "date") {
                if (objFilterValue.bTo) {
                    this.setFilterValue(objFilterValue.sFilterValue, objFilterItem.sFilterType, null, objFilterValue.mValue, objFilterItem.arrMappingData, true, objFilterItem.bFilterAllowTime);
                } else if (objFilterValue.bFrom) {
                    this.setFilterValue(objFilterValue.sFilterValue, objFilterItem.sFilterType, objFilterValue.mValue, null, objFilterItem.arrMappingData, true, objFilterItem.bFilterAllowTime);
                } else {
                    this.setFilterValue(objFilterValue.sFilterValue, objFilterItem.sFilterType, objFilterValue.mValue.sFrom, objFilterValue.mValue.sTo, objFilterItem.arrMappingData, true, objFilterItem.bFilterAllowTime);
                }
            } else {
                this.setFilterValue(objFilterValue.mValue, objFilterItem.sFilterType, objFilterItem.sFilterFromValue, objFilterItem.sFilterToValue, objFilterItem.arrMappingData, false, objFilterItem.bFilterAllowTime);
            }
        },

        actions: {
            changeShowSaveAction: function (bValue) {
                this.set("bShowSaveAction", bValue);
            },
            filterOptionsClicked: function () {
                this.filterOptionButtonClick(true);
            },
            filterTagClicked: function (objFilterItem, iIndex) {
                this.filterOptionButtonClick(false);

                //This is to stop the funcFilterPrimaryChange functions from firing during setup of filter when clicked from
                //tag
                this.set("_bFromTagClick", true);
                Ember.run.next(() => {
                    this.set("_bFromTagClick", false);
                });

                //NOTE for future, first set the _bAndOrQuestionAnd and _bAndOrQuestionOr to the correct values before
                //the next line that sets the _objSelectedFilterOption.
                //NOTE since we will be editing items out of sync setting the _bAndOrQuestionAnd and _bAndOrQuestionOr will
                //not make sense, instead during an edit we should probably only show the "Apply filter" button.

                //First let the secondaries know of preimary value so they can setup correctly before the filter tries to
                //show with the existing values
                //This needs to happen before _objSelectedFilterOption is set since the setting of the
                //_objSelectedFilterOption puts the process in motion
                if (a24Core.isEmpty(objFilterItem.sReactFilterRoute) && (objFilterItem.bFilterSecondary || objFilterItem.bFilterAnd || objFilterItem.bFilterOr || objFilterItem.sFilterType === "custom")) {
                    if (iIndex !== -1) {
                        if (a24Core.isEmpty(iIndex)) {
                            iIndex = 0;
                        }
                        var objFilterValue = objFilterItem.mFilterValue[iIndex];
                        var sFilterValue = "";
                        var arrSecondaries = this.get("objHeaderSecondaries.arrFilterSecondary_" + objFilterItem.sProperty);
                        if (objFilterItem.sFilterType === "suggest") {
                            sFilterValue = objFilterValue.mValue + "||" + objFilterValue.sFilterValue;
                        } else {
                            sFilterValue = objFilterValue.mValue;
                        }
                        if (!a24Core.isEmpty(arrSecondaries)) {
                            for (var i = 0; i < arrSecondaries.length; i++) {
                                if (!a24Core.isEmpty(arrSecondaries[i].funcFilterPrimaryChange)) {
                                    arrSecondaries[i].funcFilterPrimaryChange(sFilterValue, this.get("_objSelectedFilterOption"), arrSecondaries[i]);
                                }
                            }
                        }
                    }
                }

                this.set("_objSelectedFilterOption", objFilterItem);
                if (a24Core.isEmpty(objFilterItem.sReactFilterRoute) && (objFilterItem.bFilterSecondary || objFilterItem.bFilterAnd || objFilterItem.bFilterOr || objFilterItem.sFilterType === "custom")) {
                    if (iIndex === -1) {
                        return;
                    }

                    this.advanceFilterTagClicked(objFilterItem, iIndex);

                    return; //return needs to stay here to skip the logic below since that is for non advance filter items.
                }

                if (!a24Core.isEmpty(objFilterItem.sReactFilterRoute)) {
                    //return needs to stay here to skip the logic below since that is for non advance filter items.
                    return;
                }

                this.setFilterValue(objFilterItem.sFilterValue, objFilterItem.sFilterType, objFilterItem.sFilterFromValue, objFilterItem.sFilterToValue, objFilterItem.arrMappingData, false, objFilterItem.bFilterAllowTime);
            },
            addFilterAction: function (bDoAndNext, bDoOrNext) {
                this.addFilter(bDoAndNext, bDoOrNext);
            },
            removeFilterAction: function (sProperty, iIndex) {
                this.removeFilter(sProperty, iIndex);
            },
            closeFilterDropdown: function () {
                var objMultiDropDown = $(this.$(".multiple-select-dropdown.active"));

                if (!a24Core.isEmpty(objMultiDropDown)) {
                    objMultiDropDown.trigger("close");
                } else {
                    this.set("_bDisplayFilterChoices", false);
                    if (!a24Core.isEmpty(this.get("cardDatagridFilterDropdownCloseEvent"))) {
                        this.get("cardDatagridFilterDropdownCloseEvent")();
                    }
                }
            },
            customActionClicked: function (objAction) {
                if (!a24Core.isEmpty(this.get("customActionClicked"))) {
                    return this.get("customActionClicked")(objAction);
                }
            },
            cardDatagridColumnToggleChangeEvent: function () {
                if (!a24Core.isEmpty(this.get("cardDatagridColumnToggleChangeEvent"))) {
                    this.set("bShowSaveAction", true);
                    return this.get("cardDatagridColumnToggleChangeEvent")();
                }
            },
            setAndOrChoice: function (bAnd, bOr) {
                this.set("_bShowAddOrQuestion", false);
                this.set("_bAndOrQuestionAnd", bAnd);
                this.set("_bAndOrQuestionOr", bOr);
            },
            reloadActionClicked: function () {
                if (!a24Core.isEmpty(this.get("cardDatagridReloadButtonClickEvent"))) {
                    return this.get("cardDatagridReloadButtonClickEvent")();
                }
            },
            customFilterDisplayAddButtonEvent: function (bShow) {
                this.set("_bDisplayAddButton", bShow);
            },

            onRecieveReactFilterData: function (objData) {
                if (!a24Core.isEmpty(objData)) {
                    if (objData.type === "app_ready") {
                        this.set("_bReactFilterPopupLoading", false);
                    } else if (objData.type === "datagrid_filter_data") {
                        this.set("_bShowReactFilterIframe", false);
                        if (a24Core.isEmpty(objData.data)) {
                            this.removeFilter(this.get("_objSelectedFilterOption.sProperty"), -1, true);
                        } else {
                            this.set("_mFilterValueReact", objData.data);
                            this.addFilter(false, false);
                        }
                    } else if (objData.type === "close") {
                        this.set("_bShowReactFilterIframe", false);
                    } else if (objData.type === "datagrid_column_data") {
                        this.set("_bShowReactFilterIframe", false);
                        this.set("bShowSaveAction", true);
                        if (!a24Core.isEmpty(objData.data)) {
                            for (let i = 0; i < objData.data.length; i++) {
                                let objItem = this.get("arrAllowedHeaders").findBy("sProperty", objData.data[i].property);
                                if (!a24Core.isEmpty(objItem)) {
                                    Ember.set(objItem, "bShowHeader", objData.data[i].show);
                                }
                            }
                            if (!a24Core.isEmpty(this.get("cardDatagridColumnToggleChangeProjectionEvent"))) {
                                return this.get("cardDatagridColumnToggleChangeProjectionEvent")(objData.data);
                            }
                        }
                    }
                }
            },
            onShowReactColumnProjection: function () {
                let arrColumns = [];
                this.get("arrAllowedHeaders").forEach(objItem => {
                    if (!Ember.get(objItem, "bSilent")) {
                        arrColumns.push({
                            property: objItem.sProperty,
                            title: objItem.sTitle,
                            show: objItem.bShowHeader ? true : false
                        });
                    }
                });

                this.set("_bShowReactFilterIframe", true);
                this.set("_objReactPopupPostData", {
                    type: "trigger_route",
                    data: {
                        route: "datagrid/columnEditor/show-hide-column",
                        data: arrColumns
                    }
                });
            }
        }
    });
});