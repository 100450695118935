define("a24-ember-staffshift-core/services/chat-boxes-service-base", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objNavigation: Ember.inject.service("navigation"),
        _objTriageMenu: Ember.inject.service("triage-menu"),
        _objTitleBarService: Ember.inject.service("title-bar"),
        _objBreadCrumbService: Ember.inject.service("bread-crumb"),

        _bHideChat: true,
        _objPostData: null,

        bShowNotificationIcon: false,
        iNotificationCount: 0,

        observeNotificationCount: Ember.observer("iNotificationCount", function () {
            this.set("bShowNotificationIcon", this.get("iNotificationCount") ? true : false);
        }),

        setNotificationCount: function (iCount) {
            this.set("iNotificationCount", iCount);
        },

        showChatBox: function () {
            this.set("_bHideChat", false);
        },

        hideChatBox: function () {
            this.set("_bHideChat", true);
        },

        toggleChatBox: function () {
            this.set("_bHideChat", !this.get("_bHideChat"));
        },

        postMessage: function (objData) {
            this.set("_objPostData", objData);
        },

        generateFriendlyPageName: function () {
            var sCurrentRoute = this.get("_objNavigation.sCurrentRouteName");
            if (a24Core.isEmpty(sCurrentRoute)) {
                //this will be on init load of app
                return;
            }
            var arrRouteParts = sCurrentRoute.split(".");

            if (arrRouteParts.length > 2) {
                //If 3 parts we assume it is a tab item at end so remove that
                arrRouteParts = arrRouteParts.slice(0, 2);
            }

            var sCurrentRouteName = arrRouteParts[arrRouteParts.length - 1];

            var sPluginId = null;
            if (arrRouteParts[arrRouteParts.length - 1] === "plugin" && !a24Core.isEmpty(this.get("_objNavigation.objRouteParams")[sCurrentRoute])) {
                sPluginId = this.get("_objNavigation.objRouteParams")[sCurrentRoute].plugin_id;
            }

            var sTopLabel = "";
            var sSubLabel = "";
            var sExtraLabel = "";

            var arrMenuItems = this.get("_objTriageMenu.arrMenuResponse");
            if (a24Core.isEmpty(arrMenuItems)) {
                return;
            }
            for (var i = 0; i < arrMenuItems.length; i++) {
                if (a24Core.isEmpty(sPluginId)) {
                    for (var j = 0; j < arrMenuItems[i].arrSubmenus.length; j++) {
                        if (arrMenuItems[i].arrSubmenus[j].sReferencedNamePlaces.indexOf(sCurrentRouteName) !== -1) {
                            if (arrMenuItems[i].arrSubmenus.length === 1) {
                                sTopLabel = arrMenuItems[i].arrSubmenus[j].sLabel;
                                if (arrMenuItems[i].arrSubmenus[j].sPlaceName !== sCurrentRouteName) {
                                    sSubLabel = sCurrentRouteName;
                                }
                            } else {
                                sTopLabel = arrMenuItems[i].sLabel;
                                sSubLabel = arrMenuItems[i].arrSubmenus[j].sLabel;
                                if (arrMenuItems[i].arrSubmenus[j].sPlaceName !== sCurrentRouteName) {
                                    sExtraLabel = sCurrentRouteName;
                                }
                            }
                        }
                    }
                } else {
                    if (arrMenuItems[i].sPluginId === sPluginId) {
                        sTopLabel = arrMenuItems[i].sLabel;
                    }
                }
            }

            var objRouteParams = this.get("_objNavigation.objRouteParams");
            var objRouteParamsFlat = {};
            for (var sProp in objRouteParams) {
                if (Object.prototype.hasOwnProperty.call(objRouteParams, sProp)) {
                    Object.assign(objRouteParamsFlat, objRouteParams[sProp]);
                }
            }
            delete objRouteParamsFlat.context_id;

            var arrBreadTitles = [];
            var sTitleBar = null;
            if (a24Core.isEmpty(this.get("_objTriageMenu.sTopBarComponent"))) {
                var arrBreads = this.get("_objBreadCrumbService.arrBreadCrumbItems");
                if (!a24Core.isEmpty(arrBreads)) {
                    for (var k = 0; k < arrBreads.length; k++) {
                        arrBreadTitles.push(arrBreads[k].sTitle);
                    }
                }
            } else {
                sTitleBar = this.get("_objTitleBarService.sTitle");
            }

            var sSelectedTab = null;
            var arrTabs = this.get("_objTitleBarService.arrTabs");
            if (!a24Core.isEmpty(arrTabs)) {
                for (var l = 0; l < arrTabs.length; l++) {
                    if (arrTabs[l].bActive) {
                        sSelectedTab = arrTabs[l].sText;
                        break;
                    }
                }
            }

            var arrFinalStrings = [];
            if (!a24Core.isEmpty(sTopLabel)) {
                arrFinalStrings.push(sTopLabel);
            }
            if (!a24Core.isEmpty(sSubLabel)) {
                arrFinalStrings.push(sSubLabel);
            }
            if (!a24Core.isEmpty(sExtraLabel)) {
                arrFinalStrings.push(sExtraLabel);
            }
            if (!a24Core.isEmpty(sTitleBar)) {
                arrFinalStrings[arrFinalStrings.length - 1] = sTitleBar;

                if (!a24Core.isEmpty(sSelectedTab)) {
                    arrFinalStrings.push(sSelectedTab);
                }
            }
            if (!a24Core.isEmpty(arrBreadTitles)) {
                if (arrBreadTitles.length > 1) {
                    if (arrBreadTitles[0] !== sTopLabel) {
                        arrBreadTitles[0] = sTopLabel;
                    }
                    arrFinalStrings = arrBreadTitles;
                } else {
                    if (arrBreadTitles[0] !== sTopLabel) {
                        arrBreadTitles.push(arrBreadTitles[0]);
                        arrBreadTitles[0] = sTopLabel;
                    }
                    arrFinalStrings = arrBreadTitles;
                }
            }

            return {
                sFinalPageName: arrFinalStrings.join(" / "),
                objRouteParams: objRouteParamsFlat
            };
        }

    });
});